import React from 'react';
import PropTypes from 'prop-types';

import Text from './Text.typeRenderer';

const TextArea = (props) => {
	return <Text {...props} />;
};

TextArea.propTypes = {
	field: PropTypes.object.isRequired,
	setter: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string
};

export default TextArea;
