import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import withAuthLoading from './withLoading';

import { observer, inject } from 'mobx-react';

import styles from '../css/VerifyEmail.module.css';

@withAuthLoading
@inject('authStore')
@observer
class VerifyEmail extends React.Component {
   static propTypes = {
      authStore: PropTypes.object.isRequired
   };

   constructor(props) {
      super(props);

      this.state = {
         sent: false,
         checked: false
      };
   }

   async handleSendEmail(e) {
      e.preventDefault();
      const { authStore } = this.props;
      await authStore.sendEmailVerification();

      this.setState({
         sent: true,
         checked: false
      });
   }

   async checkStatus() {
      const { authStore } = this.props;
      const user = await authStore.reloadUser();

      if (!user.emailVerified) {
         this.setState({
            checked: true,
            sent: false
         });
         return;
      }

      authStore.updateEmailVerified(true);
   }

   render() {
      const { authStore } = this.props;
      const { sent, checked } = this.state;

      if (!authStore.loggedInUser) {
         return <Redirect to="/login" />;
      }

      if (authStore.loggedInUser.emailVerified) {
         return <Redirect to="/" />;
      }

      const ReloadBlurb = () => (
         <p>
            Can't find the e-mail?{' '}
            <a onClick={e => this.handleSendEmail(e)} href="/">
               Click here
            </a>{' '}
            to send another.
         </p>
      );

      const Body = () => (
         <div>
            {sent && (
               <p className="alert text-center alert-primary">
                  Sent! Please check your e-mail and follow the provided link.
               </p>
            )}
            {checked && (
               <p className="alert text-center alert-warning">
                  Our system indicates that you're still not verified. Did you click the link?
               </p>
            )}
            <div>
               <p>
                  You should have received an e-mail at the address{' '}
                  <strong>{authStore.loggedInUser.email}</strong> that includes a link for
                  verification. Please click on the provided link.
               </p>
               <ReloadBlurb />
            </div>
         </div>
      );

      return (
         <div className={styles.container}>
            <Body />
            <div className={styles.footerContent}>
               <a onClick={() => authStore.signOut()} href="/">
                  Sign out
               </a>
               <Button className="float-right" color="primary" onClick={e => this.checkStatus(e)}>
                  I'm Verified!
               </Button>{' '}
            </div>
         </div>
      );
   }
}
export default VerifyEmail;
