import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes, { loginRoutes, adminRoutes, globalAdminRoutes } from './constants/routes';
import { configure } from 'mobx';
import { compose } from 'recompose';

import withHeaderAndSidebar from './components/withHeaderAndSidebar';
import withAuthorization from './components/withAuthorization';
import withAuthentication from './components/withAuthentication';
import withLoginLayout from './components/withLoginLayout';
import withLoginRedirects from './components/withLoginRedirects';
import withErrorBoundry from './components/withErrorBoundry';

import NotFound from './views/404';

//Enable strict mode for mobx
configure({
   enforceActions: 'observed'
});

window.PostPerkVersion = process.env.REACT_APP_VERSION;

const standardRoutes = routes.concat(adminRoutes, globalAdminRoutes);

class App extends Component {
   render() {
      return (
         <Router>
            <div className="App">
               <Switch>
                  {loginRoutes.map(route => {
                     const composeWith = [ withLoginLayout(route.heading), withErrorBoundry ];
                     if (route.redirects) {
                        composeWith.unshift(withLoginRedirects);
                     }
                     return (
                        <Route
                           key={route.path}
                           exact={false}
                           path={route.path}
                           component={compose(...composeWith)(route.component)}
                        />
                     );
                  })}
                  {standardRoutes.map(route => (
                     <Route
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        component={compose(
                           withAuthorization,
                           withHeaderAndSidebar,
                           withErrorBoundry
                        )(route.component)}
                     />
                  ))}
                  <Route
                     key={'404'}
                     component={compose(
                        withLoginLayout('Page Not Found'),
                        withErrorBoundry
                     )(NotFound)}
                  />
               </Switch>
            </div>
         </Router>
      );
   }
}

export default withAuthentication(App);
