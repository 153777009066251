import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../css/404.module.css';

class NotFound extends React.Component {
   static propTypes = {};
   render() {
      return (
         <div className={styles.container}>
            <p className={styles.notFoundText}>404</p>
            <p>Yup, this is where we flash you the 'ol 404 and wonder what just happened.</p>
            <p>
               Perhaps you just <NavLink to="/">head back to the start</NavLink> and forget you were
               ever here?
            </p>
         </div>
      );
   }
}
export default NotFound;
