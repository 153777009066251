import React from 'react';
import PropTypes from 'prop-types';

import { Col, Input } from 'reactstrap';

import styles from '../../css/FormBuilder.module.css';

const sizeMap = {
	default: styles.smallInput,
	long: styles.longInput,
	longest: styles.longestInput
};

const Text = (props) => {
	const { field, setter, name, value, builderState } = props;

	return (
		<Col sm="auto" fluid="true">
			<Input
				className={
					field.size && field.size in sizeMap
						? sizeMap[field.size]
						: sizeMap.default
				}
				type={field.type}
				step={field.step ? field.step : ''}
				min={typeof field.min !== undefined ? field.min : ''}
				onChange={(e) =>
					field.type === 'number'
						? setter(parseFloat(e.target.value))
						: setter(e.target.value)
				}
				name={name}
				id={`${name}Input`}
				value={
					typeof value !== 'undefined' && !Number.isNaN(value)
						? value
						: ''
				}
				placeholder={
					typeof field.placeholder === 'function'
						? field.placeholder(builderState)
						: field.placeholder || ''
				}
				required={field.required ? true : false}
			/>

			{field.invalidText && (
				<p className="invalid-feedback">{field.invalidText}</p>
			)}

			{field.noteText && (
				<small className={styles.deets}>{field.noteText}</small>
			)}
		</Col>
	);
};

Text.propTypes = {
	field: PropTypes.object.isRequired,
	setter: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	builderState: PropTypes.object
};

export default Text;
