import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { Alert } from 'reactstrap';

import FormBuilder from '../../formBuilder';

@inject('brandStore')
@observer
class AdminActivateBrand extends React.Component {
   constructor(props) {
      super(props);

      this.state = {};
   }

   static propTypes = {
      brandStore: PropTypes.object
   };

   render() {
      const { brandStore } = this.props;

      const readyToActivate = brandStore.essentialsCollected && brandStore.finalized;

      return (
         <div>
            {!readyToActivate && (
               <Alert color="warning">
                  You'll need to finish filling out the information in the{' '}
                  <strong>
                     {!brandStore.essentialsCollected ? 'Setup Wizard' : 'Admin Finalize page'}
                  </strong>{' '}
                  before you can activate this brand
               </Alert>
            )}

            <FormBuilder
               fields={{
                  activateBrand: {
                     title: `Activate ${brandStore.name}`,
                     type: 'select',
                     disabled: !(brandStore.active || readyToActivate),
                     options: {
                        false: 'False',
                        true: 'True'
                     },
                     mobxSetter: val => brandStore.setActive(val),
                     mobxGetter: () => (brandStore.active ? 'true' : 'false'),
                     required: true
                  }
               }}
            />
         </div>
      );
   }
}

export default AdminActivateBrand;
