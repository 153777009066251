import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import withAuthLoading from './withLoading';

import styles from '../css/Login.module.css';

@withAuthLoading
@withRouter
@inject('authStore')
@observer
class LoginForm extends React.Component {
   static propTypes = {
      validated: PropTypes.bool,
      authStore: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
   };

   constructor(props) {
      super(props);

      this.state = {
         email: '',
         password: '',
         validated: false
      };
   }

   validateForm() {
      this.setState({
         validated: true
      });

      return this.form.checkValidity();
   }

   async handleLogin(e) {
      e.preventDefault();

      if (!this.validateForm()) {
         return;
      }

      const { email, password } = this.state;
      await this.props.authStore.logIn(email, password);
   }

   render() {
      const { email, password, validated } = this.state;

      return (
         <form
            ref={form => (this.form = form)}
            className={`${styles.form} ${validated ? 'was-validated' : ''}`}
            onSubmit={e => this.handleLogin(e)}
            data-testid="loginForm"
         >
            <div className="form-group">
               <input
                  name="login-email"
                  type="email"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  className="form-control"
                  placeholder="Enter E-mail Address"
                  data-testid="emailInput"
                  required
               />
               <div className="invalid-feedback">Please provide a valid email address</div>
            </div>
            <div className="form-group">
               <input
                  name="login-password"
                  type="password"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                  className="form-control"
                  placeholder="Enter Password"
                  data-testid="passwordInput"
                  required
               />
               <div className="invalid-feedback">Please provide a valid password</div>
            </div>
            <div className="clearfix">
               <button
                  data-testid="loginBtn"
                  type="submit"
                  className="btn my-2 btn-primary float-right"
               >
                  Login
               </button>
            </div>
            <div className={styles.actions}>
               <NavLink tabIndex="-1" className="nav-link text-center" to="/signup">
                  Need to create an account?
               </NavLink>
               <NavLink className="nav-link text-center" to="/reset">
                  Forgot your password?
               </NavLink>
            </div>
         </form>
      );
   }
}
export default LoginForm;
