import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

const withAuthorization = Component => {
   class WithAuthorization extends React.Component {
      static propTypes = {
         authStore: PropTypes.object.isRequired,
         history: PropTypes.object.isRequired
      };

      render() {
         const { authStore } = this.props;
         const user = authStore.loggedInUser;

         if (!user) {
            return <Redirect to="/signup" />;
         }

         if (user && !user.emailVerified) {
            return <Redirect to="/verify-email" />;
         }

         return <Component />;
      }
   }

   return compose(
      withRouter,
      inject('authStore'),
      observer
   )(WithAuthorization);
};

export default withAuthorization;
