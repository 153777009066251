import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { functions } from '../../firebase';

import withLoading from '../../components/withLoading';
import FormBuilder from '../../formBuilder';

const withLoadingType = (Component) => {
	return withLoading(Component, 'manual');
};

@withLoadingType
@inject('adminStore', 'brandStore')
@observer
class AdminSendDemoEmail extends React.Component {
	state = {
		email: '',
		firstName: 'Jessica',
		brands: []
	};

	static propTypes = {
		adminStore: PropTypes.object,
		brandStore: PropTypes.object,
		loaderPromise: PropTypes.func
	};

	async sendEmail() {
		const { email, firstName, brands } = this.state;
		const { brandId } = this.props.brandStore;

		const sending = functions.sendDemoEmail({
			email,
			firstName,
			brands,
			originBrand: brandId
		});

		this.props.loaderPromise(sending);
		await sending;

		alert('Sent!');

		this.setState({ email: '' });
	}

	render() {
		return (
			<FormBuilder
				afterSave={() => this.sendEmail()}
				buttonDisplayText="Send"
				fields={{
					sendTo: {
						title: 'Email To',
						type: 'email',
						mobxSetter: (email) => this.setState({ email }),
						mobxGetter: () => '',
						placeholder: 'person@brand.com',
						required: true,
						invalidText: 'Please enter valid email',
						size: 'long'
					},
					customerFirstName: {
						title: 'Customer name',
						type: 'text',
						mobxSetter: (firstName) => this.setState({ firstName }),
						mobxGetter: () => this.state.firstName,
						required: true,
						size: 'long'
					},
					offerBrands: {
						title: 'Offer Brands',
						type: 'brand',
						brandFilter: (brand, allBrands) =>
							!allBrands.includes(brand.docId),
						invalidText: 'Must select at least 1 brand',
						max: 3,
						mobxSetter: (brandString) =>
							this.setState({
								brands: brandString
									.split('|')
									.filter((item) => !!item)
							}),
						mobxGetter: () => {},
						required: true
					}
				}}
			/>
		);
	}
}

export default AdminSendDemoEmail;
