import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Container } from 'reactstrap';

import SaveButton from '../components/SaveButton';
import styles from '../css/FormBuilder.module.css';
import typeRenderers from './typeRenderers';

class FormBuilder extends Component {
	static propTypes = {
		fields: PropTypes.object.isRequired,
		afterSave: PropTypes.func,
		buttonDisplayText: PropTypes.string,
		submitEmitter: PropTypes.object,
		displaySaveBtn: PropTypes.bool
	};

	static defaultProps = {
		buttonDisplayText: '',
		displaySaveBtn: true
	};

	state = {
		validated: false
	};

	compareHandlers = {
		image: (name, field) => {
			return field.mobxGetter() && this.state[name]
				? field.mobxGetter().size === this.state[name].size
				: this.compareHandlers.default(name, field);
		},
		default: (name, field) => {
			return field.mobxGetter() === this.state[name];
		}
	};

	handleFormSubmit(e) {
		e && e.preventDefault();

		if (this.validateForm()) {
			this.submitFormData();
		}
	}

	validateForm(e) {
		e && e.preventDefault();

		this.setState({
			validated: true
		});

		return this.form.checkValidity();
	}

	async submitFormData(subset = []) {
		const { fields } = this.props;

		const selectFields = !subset.length
			? fields
			: subset.reduce((obj, curr) => {
					obj[curr] = fields[curr];
					return obj;
			  }, {});

		const queue = [];

		for (const [name, field] of Object.entries(selectFields)) {
			// Field not changed, don't save
			if (!this.changedFields.includes(name)) {
				continue;
			}

			if (field.conditional && !field.conditional(this.state)) {
				continue;
			}

			// Add promise to queue
			queue.push(
				Promise.resolve(field.mobxSetter(this.state[name], this.state))
			);
		}

		// Wait for any queued saves
		await Promise.all(queue);

		if (typeof this.props.afterSave === 'function') {
			await this.props.afterSave();
		}
	}

	get changedFields() {
		return Object.keys(this.props.fields).reduce((changed, name) => {
			const field = this.props.fields[name];
			const type =
				field.type in this.compareHandlers ? field.type : 'default';

			if (!this.compareHandlers[type](name, field)) {
				changed.push(name);
			}

			return changed;
		}, []);
	}

	componentDidMount() {
		const { submitEmitter } = this.props;
		this.setInitialState();

		if (submitEmitter) {
			submitEmitter.addEventListener('click', () =>
				this.handleFormSubmit()
			);
		}
	}

	setInitialState() {
		const initialObject = Object.keys(this.props.fields).reduce(
			(map, currName) => {
				const field = this.props.fields[currName];
				const getterValue = field.mobxGetter();

				map[currName] =
					typeof getterValue !== 'undefined'
						? getterValue
						: field.type === 'select'
						? Object.keys(field.options)[0]
						: field.defaultValue
						? field.defaultValue
						: '';

				return map;
			},
			{}
		);

		this.setState(initialObject);
	}

	renderField(field, name) {
		if (field.conditional && !field.conditional(this.state)) {
			return null;
		}

		const Field = typeRenderers[field.type];

		return (
			<FormGroup row key={name}>
				<Label sm={3}>
					{field.title}
					{field.required ? ' *' : ''}
				</Label>
				<Field
					value={this.state[name]}
					field={field}
					name={name}
					setter={(val) => {
						this.setState({ [name]: val }, () => {
							if (field.saveOnChange) {
								this.submitFormData([name]);
							}
						});
					}}
					builderState={this.state}
				/>
			</FormGroup>
		);
	}

	render() {
		const { fields, buttonDisplayText, displaySaveBtn } = this.props;

		return (
			<form
				className={this.state.validated ? 'was-validated ' : ''}
				onSubmit={(e) => this.handleFormSubmit(e)}
				ref={(form) => (this.form = form)}
			>
				<Container className={styles.formContainer}>
					{Object.keys(fields).map((name) =>
						this.renderField(fields[name], name)
					)}
				</Container>

				{displaySaveBtn && (
					<SaveButton
						displayText={buttonDisplayText}
						onSubmitHandler={(e) => this.handleFormSubmit(e)}
						enableCondition={!!this.changedFields.length}
					/>
				)}
			</form>
		);
	}
}

export default FormBuilder;
export * from './fields';
