import React from 'react';
import { Button } from 'reactstrap';
import { functions } from '../firebase';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import BrandSelect from '../components/BrandSelect';

import styles from '../css/AdminTools.module.css';

@inject('appStore')
@observer
class AdminTools extends React.Component {
	static propTypes = {
		appStore: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			brands: []
		};
	}

	previewSelectHandler(selected) {
		sessionStorage.setItem('PostPerk.adminPreviewBrand', selected.docId);
		window.location.reload();
	}

	stopPreview(e) {
		e && e.preventDefault();

		sessionStorage.clear();
		window.location.reload();
	}

	async handleAddBrandClick() {
		const brandName = window.prompt('Enter brand name');

		if (!brandName) {
			alert('Name required, aborting');
			return;
		}

		const ownerName = window.prompt('Enter full name of owner (optional)');
		const ownerEmail = window.prompt(
			'Enter owner email address (optional)'
		);

		const brandRes = await functions.createBrand({
			name: brandName,
			ownerName,
			ownerEmail
		});

		if (brandRes.hasOwnProperty('error')) {
			alert('Error with request, aborting');
			return;
		}

		this.previewSelectHandler({ docId: brandRes.id });
	}

	render() {
		const { appStore } = this.props;

		return (
			<div className={styles.container}>
				<p className={styles.adminLabel}>Admin Mode</p>
				<BrandSelect
					placeholder={(loading, brands) => {
						const previewingBrand = brands.find(
							(brand) => brand.docId === appStore.previewingBrand
						);
						return previewingBrand
							? previewingBrand.name
							: loading
							? 'Loading...'
							: 'Switch to...';
					}}
					onChange={(obj) => this.previewSelectHandler(obj)}
				/>

				<Button
					color="info"
					size="sm"
					className={styles.newBrandBtn}
					onClick={(e) => this.handleAddBrandClick(e)}
				>
					Add
				</Button>
			</div>
		);
	}
}

export default AdminTools;
