import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import withAuthLoading from './withLoading';

import styles from '../css/Login.module.css';

@withAuthLoading
@withRouter
@inject('authStore')
@observer
class ChangePassword extends React.Component {
   static propTypes = {
      authStore: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
   };

   constructor(props) {
      super(props);

      this.state = {
         password: '',
         oldPassword: '',
         confirmPassword: '',
         validated: false,
         sent: false
      };
   }

   validateForm() {
      this.setState({
         validated: true
      });

      return this.form.checkValidity();
   }

   async handleChange(e) {
      e.preventDefault();
      const { authStore } = this.props;

      try {
         if (!this.validateForm()) {
            return;
         }

         const { oldPassword, password } = this.state;

         const result = await authStore.changePassword({ password, oldPassword });

         if (result && result.error) {
            return;
         }

         this.setState({
            sent: true
         });
      } catch (e) {
         this.setState(() => {
            throw e;
         }); // "Rethrow"
      }
   }

   render() {
      const { password, oldPassword, confirmPassword, validated, sent } = this.state;
      const { authStore, history } = this.props;

      if (sent) {
         return (
            <div>
               <p className="text-center">Your password has been changed</p>
               <a
                  href="/"
                  onClick={async e => {
                     e.preventDefault();
                     await authStore.signOut();
                     history.push('/login');
                  }}
                  className="nav-link text-center"
                  to="/login"
               >
                  Go to Login
               </a>
            </div>
         );
      }

      return (
         <form
            ref={form => (this.form = form)}
            className={`${styles.form} ${validated ? 'was-validated' : ''}`}
            onSubmit={e => this.handleLogin(e)}
         >
            <div className="form-group">
               <input
                  name="sign-up-oldPassword"
                  type="password"
                  value={oldPassword}
                  onChange={e => this.setState({ oldPassword: e.target.value })}
                  className="form-control"
                  placeholder="Enter Current Password"
                  minLength="8"
                  required
               />
               <div className="invalid-feedback">Please provide a password</div>
            </div>
            <div className="form-group">
               <input
                  name="sign-up-password"
                  type="password"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                  className="form-control"
                  placeholder="Enter New Password"
                  minLength="8"
                  required
               />
               <div className="invalid-feedback">Please provide a password</div>
            </div>
            <div className="form-group">
               <input
                  name="sign-up-password-confirm"
                  type="password"
                  value={confirmPassword}
                  onChange={e => this.setState({ confirmPassword: e.target.value })}
                  className="form-control"
                  placeholder="Confirm New Password"
                  required
                  onInput={({ target }) => {
                     if (target.value !== password) {
                        target.setCustomValidity('This must match the password field');
                     } else {
                        target.setCustomValidity('');
                     }
                  }}
               />
               <div className="invalid-feedback">Your password does not match</div>
            </div>

            <div className={styles.actions}>
               <button
                  type="submit"
                  onClick={e => this.handleChange(e)}
                  className="btn btn-primary mx-auto"
               >
                  Change Password
               </button>
            </div>
         </form>
      );
   }
}
export default ChangePassword;
