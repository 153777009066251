import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import FormBuilder, { resolveFields, resolveCollection } from '../formBuilder';

const withWizardToBuilder = (currentStep) => {
	@inject('wizardStore')
	@observer
	class WithWizardToBuilder extends React.Component {
		static propTypes = {
			advance: PropTypes.func,
			wizard: PropTypes.bool,
			wizardStore: PropTypes.object,
			Fallback: PropTypes.func
		};

		render() {
			const { advance, wizardStore } = this.props;

			const fields = currentStep.integrationFields
				? currentStep.integrationFields
				: currentStep.fieldCollection
				? resolveCollection(currentStep.fieldCollection)
				: resolveFields(currentStep.fields);

			return (
				<FormBuilder
					displaySaveBtn={false}
					submitEmitter={wizardStore.nextClickEmitter}
					afterSave={() => advance()}
					fields={fields}
				/>
			);
		}
	}

	return WithWizardToBuilder;
};

export default withWizardToBuilder;
