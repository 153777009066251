import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { functions } from '../../firebase';

import withLoading from '../../components/withLoading';
import FormBuilder from '../../formBuilder';

const withLoadingType = (Component) => {
	return withLoading(Component, 'manual');
};

@withLoadingType
@inject('adminStore', 'brandStore')
@observer
class AdminSendDemoEmail extends React.Component {
	state = {
		sendTo: '',
		emailId: '',
		method: 'auto'
	};

	static propTypes = {
		adminStore: PropTypes.object,
		brandStore: PropTypes.object,
		loaderPromise: PropTypes.func
	};

	async sendEmail() {
		const { sendTo, emailId, method } = this.state;

		const payload = {
			email: sendTo,
			emailId
		};

		if (method) {
			payload.method = method;
		}

		const sending = functions.sendDemoEmail(payload);

		this.props.loaderPromise(sending);
		await sending;

		alert('Sent!');

		this.setState({ sendTo: '' });
	}

	render() {
		return (
			<FormBuilder
				afterSave={() => this.sendEmail()}
				buttonDisplayText="Send"
				fields={{
					emailId: {
						title: 'Email ID',
						type: 'text',
						mobxSetter: (emailId) => this.setState({ emailId }),
						mobxGetter: () => '',
						required: true,
						invalidText: 'Please enter valid sendTo',
						size: 'long'
					},
					sendTo: {
						title: 'Email To',
						type: 'email',
						mobxSetter: (sendTo) => this.setState({ sendTo }),
						mobxGetter: () => '',
						placeholder: 'person@brand.com',
						required: true,
						invalidText: 'Please enter valid sendTo',
						size: 'long'
					},
					sendingService: {
						title: 'Sending Service',
						type: 'select',
						options: {
							auto: 'Auto',
							ses: 'Amazon SES',
							grid: 'SendGrid',
							mailgun: 'Mail Gun'
						},
						mobxSetter: (method) => this.setState({ method }),
						mobxGetter: () => '',
						required: false
					}
				}}
			/>
		);
	}
}

export default AdminSendDemoEmail;
