import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import withAuthLoading from './withLoading';
import qs from 'query-string';
import { Container, Row, Col } from 'reactstrap';

import TermsConditionsModal from './TermsConditionsModal';

import styles from '../css/Login.module.css';

const query = qs.parse(window.location.search);

@withAuthLoading
@inject('authStore')
@observer
class SignupForm extends React.Component {
   static propTypes = {
      authStore: PropTypes.object.isRequired,
      setSubheading: PropTypes.func.isRequired
   };

   constructor(props) {
      super(props);

      this.state = {
         validated: false,
         email: '',
         password: '',
         confirmPassword: '',
         fullName: '',
         companyName: '',
         tcChecked: false,
         showTerms: false
      };
   }

   validateForm() {
      this.setState({
         validated: true
      });

      return this.form.checkValidity();
   }

   async handleSignUp(e) {
      e.preventDefault();

      if (!this.validateForm()) {
         return;
      }

      const { email, password, companyName, fullName } = this.state;
      await this.props.authStore.signUp({ email, password, companyName, fullName });
   }

   toggleTerms() {
      this.setState({
         showTerms: !this.state.showTerms
      });
   }

   termsAgree() {
      this.setState({
         tcChecked: true,
         showTerms: false
      });
   }

   render() {
      const {
         email,
         password,
         confirmPassword,
         validated,
         fullName,
         companyName,
         tcChecked,
         showTerms
      } = this.state;

      return (
         <Container className={styles.signUpTop}>
            <form
               ref={form => (this.form = form)}
               className={`${styles.form} ${validated ? 'was-validated' : ''}`}
               onSubmit={e => this.handleSignUp(e)}
               data-testid="signupForm"
            >
               <Row>
                  <Col sm="12">
                     <div className={`form-group ${query.plan ? styles.spacer : ''}`}>
                        <input
                           name="sign-up-name"
                           type="text"
                           value={fullName}
                           onChange={e => this.setState({ fullName: e.target.value })}
                           className="form-control"
                           placeholder="Enter Full Name"
                           data-testid="fullNameInput"
                           required
                        />
                        <div className="invalid-feedback">Please provide your full name</div>
                     </div>
                     <div className="form-group">
                        <input
                           name="sign-up-company-name"
                           type="text"
                           value={companyName}
                           onChange={e => this.setState({ companyName: e.target.value })}
                           className="form-control"
                           placeholder="Enter Company Name"
                           data-testid="companyNameInput"
                           required
                        />
                        <div className="invalid-feedback">Please provide your company name</div>
                     </div>
                     <div className="form-group">
                        <input
                           name="sign-up-email"
                           type="email"
                           value={email}
                           onChange={e => this.setState({ email: e.target.value })}
                           className="form-control"
                           placeholder="Enter E-mail Address"
                           data-testid="emailInput"
                           required
                        />
                        <div className="invalid-feedback">Please provide a valid email address</div>
                     </div>

                     <div className="form-group">
                        <input
                           name="sign-up-password"
                           type="password"
                           value={password}
                           onChange={e => this.setState({ password: e.target.value })}
                           className="form-control"
                           placeholder="Enter Password"
                           data-testid="passwordInput"
                           minLength="8"
                           required
                        />
                        <div data-testid="passwordInvalidMessage" className="invalid-feedback">
                           Please provide a password
                        </div>
                     </div>
                     <div className="form-group">
                        <input
                           name="sign-up-password-confirm"
                           type="password"
                           value={confirmPassword}
                           onChange={e => this.setState({ confirmPassword: e.target.value })}
                           className="form-control"
                           placeholder="Confirm Password"
                           data-testid="confirmPasswordInput"
                           required
                           onInput={({ target }) => {
                              if (target.value !== password) {
                                 target.setCustomValidity('This must match the password field');
                              } else {
                                 target.setCustomValidity('');
                              }
                           }}
                        />
                        <div className="invalid-feedback">Your password does not match</div>
                     </div>
                     <div className={`${styles.tcCheckContainer} form-check`}>
                        <input
                           className="form-check-input"
                           type="checkbox"
                           checked={tcChecked}
                           data-testid="termsCheckbox"
                           onChange={() => {}}
                           onClick={e => {
                              if (tcChecked) {
                                 this.setState({
                                    tcChecked: false
                                 });
                                 return;
                              }

                              e.preventDefault();
                              this.setState({
                                 showTerms: true
                              });
                           }}
                           id="tcCheck"
                           required
                        />
                        <label className="form-check-label" htmlFor="tcCheck">
                           I understand and accept the PostPerk T&amp;Cs
                        </label>
                     </div>

                     <TermsConditionsModal
                        agree={() => this.termsAgree()}
                        visible={showTerms}
                        toggleModal={() => this.toggleTerms()}
                     />
                  </Col>
               </Row>
               <Row>
                  <div className={styles.actions}>
                     <div className={styles.actions}>
                        <NavLink tabIndex="-1" className="nav-link text-center pt-0" to="/login">
                           Already have an account?
                        </NavLink>
                     </div>
                     <button data-testid="startBtn" type="submit" className="btn btn-primary ">
                        Create Account
                     </button>
                  </div>
               </Row>
            </form>
         </Container>
      );
   }
}
export default SignupForm;
