import React from 'react';
import { observer, inject } from 'mobx-react';
import * as Icon from 'react-feather';
import PropTypes from 'prop-types';

import WizardWelcome from '../views/Settings/WizardWelcome';
import IntegrationMain from './IntegrationMain';

import styles from '../css/AppIntro.module.css';

@inject('wizardStore', 'brandStore')
@observer
class AppIntroSteps extends React.Component {
   static propTypes = {
      brandStore: PropTypes.object.isRequired,
      wizardStore: PropTypes.object.isRequired
   };

   state = {
      startReviewModalVisible: false
   };

   render() {
      const { brandStore, wizardStore } = this.props;

      const integrationDone = brandStore.integrated;

      return (
         <div>
            <ul className={`text-larger mt-4 list-group ${styles.list}`}>
               <li
                  className={`${styles.itemAction} ${
                     integrationDone ? styles.done : {}
                  } list-group-item list-group-item-action`}
                  onClick={e => {
                     e.preventDefault();
                     wizardStore.startWizard(
                        [
                           {
                              title: 'Shop Platform',
                              type: 'builder',
                              fields: ['platform']
                           },
                           {
                              title: 'Order System Integration',
                              component: IntegrationMain,
                              type: 'manual'
                           }
                        ],
                        {
                           onFinish: () => {
                              brandStore.setIntegrated(true);
                           }
                        }
                     );
                  }}
               >
                  {integrationDone ? (
                     <Icon.CheckSquare className={styles.feather} />
                  ) : (
                     <Icon.Square className={styles.feather} />
                  )}
                  Integrate with your order system
               </li>

               {
                  {
                     false: (
                        <li
                           className={`${styles.itemAction} list-group-item list-group-item-action`}
                           onClick={e => {
                              e.preventDefault();
                              wizardStore.startWizard([
                                 {
                                    title: 'Welcome',
                                    component: WizardWelcome
                                 },
                                 {
                                    title: 'Your Brand Details',
                                    type: 'builder',
                                    fieldCollection: 'brandDetails'
                                 },
                                 {
                                    title: 'Your Address',
                                    type: 'builder',
                                    fieldCollection: 'brandAddress'
                                 },
                                 {
                                    title: 'Offer Details',
                                    type: 'builder',
                                    fieldCollection: 'offerDetails'
                                 }
                              ]);
                           }}
                        >
                           <Icon.Square className={styles.feather} />
                           Provide basic information with the{' '}
                           <span className={styles.setupText}>
                              Setup Wizard
                           </span>
                        </li>
                     ),
                     true: (
                        <li className={`${styles.done} list-group-item`}>
                           <Icon.CheckSquare className={styles.feather} />
                           Provide basic information with the{' '}
                           <span className={styles.setupText}>
                              Setup Wizard
                           </span>
                        </li>
                     )
                  }[brandStore.essentialsCollected.toString()]
               }
            </ul>
         </div>
      );
   }
}
export default AppIntroSteps;
