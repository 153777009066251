import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import { Col } from 'reactstrap';

import styles from '../../css/FormBuilder.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const DateField = ({ field, setter, value }) => {
	return (
		<Col sm="auto" fluid="true">
			<DatePicker
				selected={value ? new Date(value) : ''}
				onChange={(date) => setter(date ? date.toISOString() : '')}
				minDate={new Date()}
			/>

			{field.invalidText && (
				<p className="invalid-feedback">{field.invalidText}</p>
			)}

			{field.noteText && (
				<small className={styles.deets}>{field.noteText}</small>
			)}
		</Col>
	);
};

DateField.propTypes = {
	field: PropTypes.object.isRequired,
	setter: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	builderState: PropTypes.object
};

export default DateField;
