import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

import AdminTools from './AdminTools';

import styles from '../css/Layout.module.css';
import dropdownStyles from '../css/Dropdown.module.css';

import * as Icon from 'react-feather';

@withRouter
@inject('authStore')
@observer
class TopContent extends Component {
   static propTypes = {
      authStore: PropTypes.object.isRequired,
      history: PropTypes.object
   };

   constructor(props) {
      super(props);

      this.state = {
         dropdownActive: false
      };
   }

   toggleDropdown() {
      this.setState({
         dropdownActive: !this.state.dropdownActive
      });
   }

   render() {
      const { authStore } = this.props;
      const isAdmin = authStore.currentUser && authStore.currentUser.admin ? true : false;

      return (
         <nav className={styles.topContent}>
            {
               {
                  true: <AdminTools />,
                  false: <div /> // Need empty div for styling at the moment
               }[isAdmin.toString()]
            }
            <Dropdown
               direction="down"
               isOpen={this.state.dropdownActive}
               toggle={() => this.toggleDropdown()}
               className={dropdownStyles.dropdown}
            >
               <DropdownToggle className={dropdownStyles.toggle} caret>
                  <Icon.User size={22} color="#fff" />
               </DropdownToggle>
               <DropdownMenu className={dropdownStyles.menu}>
                  <DropdownItem
                     onClick={e => {
                        e.preventDefault();
                        this.props.history.push('/change-password');
                     }}
                     className={dropdownStyles.item}
                  >
                     Change Password
                  </DropdownItem>
                  <DropdownItem
                     onClick={e => {
                        e.preventDefault();
                        this.props.authStore.signOut();
                     }}
                     className={dropdownStyles.item}
                  >
                     Sign out
                  </DropdownItem>
               </DropdownMenu>
            </Dropdown>
         </nav>
      );
   }
}

export default TopContent;
