import { observable, action, computed, reaction } from 'mobx';
import { addProtocolToLink } from '../util';
import dbStore from './dbStore';

const castBool = (val) => {
	return typeof val === 'string' ? val === 'true' : val;
};

class BrandStore {
	@observable
	name = '';

	@observable
	brandId = '';

	@observable
	logo = null;

	@observable
	shopifyShop = null;

	@observable
	platformSelection = null;

	@observable
	reviewStarted = null;

	@observable
	integrated = false;

	@observable
	primaryColor = '';
	@observable
	highlightColor = '';

	@observable
	emailDisplayName = '';
	@observable
	emailReplyTo = '';

	@observable
	offerType = '';
	@observable
	offerAmount = '';
	@observable
	offerLink = '';
	@observable
	offerCodeSource = '';
	@observable
	offerGlobalCode = '';
	@observable
	offerSortFactor = 0;
	@observable
	offerManagedCode = '';

	@observable
	tagline = '';

	@observable
	cpa = '';
	@observable
	cpaType = '';

	@observable
	billingMethod = '';

	@observable
	thirdSlot = false;

	@observable
	thirdSlotFactor = null;

	@observable
	fetchRenewals = false;

	@observable
	allowRepeatEmails = false;

	@observable
	abortRenewals = false;

	@observable
	promoteOnly = false;

	@observable
	payoutViaAffiliate = false;

	@observable
	productType = '';

	@observable
	brandCategory = '';

	@observable
	active = false;

	@observable
	cardImage = null;

	@observable
	blacklist = [];

	@observable
	whitelist = [];

	@observable
	tags = [];

	@observable
	codeBlacklist = [];

	@observable brandFilterType = null;

	@observable demoGender = null;

	@observable
	addressDetail = '';

	@observable
	addressCity = '';

	@observable
	addressState = '';

	@observable
	addressZip = '';

	@observable
	addressCountry = '';

	@observable customerDataCleanup = null;

	@observable emailMethod = '';

	@observable emailSchedule = {};

	@observable ratioThreshold = null;

	@observable tplOffer = {};

	@observable tplReminder = {};

	@observable primaryPartner = '';

	@observable supportEmail = '';

	@observable freeConversionsUntil = '';

	@observable adminShop = false;

	@observable adminProxyShop = '';

	@action
	setBrandSupportEmail(val) {
		this.supportEmail = val;

		dbStore.sendToDB({ supportEmail: val });
	}

	@action
	setOfferSortFactor(val) {
		this.offerSortFactor = val;

		dbStore.sendToDB({ offerSortFactor: val });
	}

	@action
	setPrimaryPartner(brandId) {
		this.primaryPartner = brandId;
		dbStore.sendToDB({ primaryPartner: brandId });
	}

	@action
	setTplReminder(obj) {
		this.tplReminder = { ...this.tplReminder, ...obj };

		for (const [key, value] of Object.entries(obj)) {
			dbStore.sendToDB({ [`tplReminder.${key}`]: value });
		}
	}

	@action
	setTplOffer(obj) {
		this.tplOffer = { ...this.tplOffer, ...obj };

		for (const [key, value] of Object.entries(obj)) {
			dbStore.sendToDB({ [`tplOffer.${key}`]: value });
		}
	}

	@action
	setBrandId(val) {
		this.brandId = val;
	}

	@action
	setRatioThreshold(val) {
		this.ratioThreshold = val;
		dbStore.sendToDB({ ratioThreshold: val });
	}

	@action
	getEmailScheduleAmount() {
		const keys = Object.keys(this.emailSchedule);
		if (!keys.length) {
			return 1;
		}

		return this.emailSchedule[keys[0]];
	}

	@action
	getEmailScheduleType() {
		const keys = Object.keys(this.emailSchedule);
		if (!keys.length) {
			return '';
		}

		return keys[0];
	}

	@action
	setEmailSchedule(type, duration) {
		this.emailSchedule = { [type]: duration };
		dbStore.sendToDB({ emailSchedule: { [type]: duration } });
	}

	@action
	setEmailMethod(val) {
		this.emailMethod = val;
		dbStore.sendToDB({ emailMethod: val });
	}

	@action
	setFreeConversionsUntil(val) {
		this.freeConversionsUntil = val;
		dbStore.sendToDB({ freeConversionsUntil: val ? new Date(val) : '' });
	}

	@action
	setCustomerDataCleanup(val) {
		this.customerDataCleanup = val;
		dbStore.sendToDB({ customerDataCleanup: val });
	}

	@action
	setBrandCountry(country) {
		this.addressCountry = country;
		dbStore.sendToDB({ addressCountry: country });
	}

	@action
	setBrandZip(zip) {
		this.addressZip = zip;
		dbStore.sendToDB({ addressZip: zip });
	}

	@action
	setBrandAddress(addr) {
		this.addressDetail = addr;
		dbStore.sendToDB({ addressDetail: addr });
	}

	@action
	setBrandCity(city) {
		this.addressCity = city;
		dbStore.sendToDB({ addressCity: city });
	}

	@action
	setBrandState(state) {
		this.addressState = state;
		dbStore.sendToDB({ addressState: state });
	}

	@action
	addCodeToBlacklist(code) {
		this.codeBlacklist.push(code);
		dbStore.sendToDB({ codeBlacklist: this.codeBlacklist });
	}

	@action
	removeCodeFromBlacklist(code) {
		this.codeBlacklist = this.codeBlacklist.filter(
			(docId) => docId !== code
		);
		dbStore.sendToDB({ codeBlacklist: this.codeBlacklist });
	}

	@action
	addBrandToBlacklist(brandId) {
		this.blacklist.push(brandId);
		dbStore.sendToDB({ blacklist: this.blacklist });
	}

	@action
	removeBrandFromBlacklist(brandId) {
		this.blacklist = this.blacklist.filter((docId) => docId !== brandId);
		dbStore.sendToDB({ blacklist: this.blacklist });
	}

	@action
	addBrandToWhitelist(brandId) {
		this.whitelist.push(brandId);
		dbStore.sendToDB({ whitelist: this.whitelist });
	}

	@action
	removeBrandFromWhitelist(brandId) {
		this.whitelist = this.whitelist.filter((docId) => docId !== brandId);
		dbStore.sendToDB({ whitelist: this.whitelist });
	}

	@action
	setCodeBlacklist(list) {
		this.codeBlacklist = list;
		dbStore.sendToDB({ codeBlacklist: list });
	}

	@action
	setBlacklist(list) {
		this.blacklist = list;
		dbStore.sendToDB({ blacklist: list });
	}

	@action
	setTags(tags) {
		this.tags = tags;
		dbStore.sendToDB({ tags });
	}

	@action
	setWhitelist(list) {
		this.whitelist = list;
		dbStore.sendToDB({ whitelist: list });
	}

	@action
	setCardImage(file) {
		this.cardImage = file;
		dbStore.sendToStorage({ file, type: 'card' });
	}

	@action
	setActive(active) {
		const bool = castBool(active);

		this.active = bool;

		dbStore.sendToDB({ active: bool });
	}

	@action
	setBrandCategory(category) {
		this.brandCategory = category;

		dbStore.sendToDB({ brandCategory: category });
	}

	@action
	setProductType(productType) {
		if (!productType && !this.productType) {
			return;
		}

		this.productType = productType;

		dbStore.sendToDB({ productType });
	}

	@action
	setPromoteOnly(promoteOnly) {
		const bool = castBool(promoteOnly);

		this.promoteOnly = bool;

		dbStore.sendToDB({ promoteOnly: bool });
	}

	@action
	setPayoutViaAffiliate(payoutViaAffiliate) {
		const bool = castBool(payoutViaAffiliate);

		this.payoutViaAffiliate = bool;

		dbStore.sendToDB({ payoutViaAffiliate: bool });
	}

	@action
	setFetchRenewals(fetchRenewals) {
		const bool = castBool(fetchRenewals);

		this.fetchRenewals = bool;

		dbStore.sendToDB({ fetchRenewals: bool });
	}

	@action
	setAllowRepeatEmails(allow) {
		const bool = castBool(allow);

		this.allowRepeatEmails = bool;

		dbStore.sendToDB({ allowRepeatEmails: bool });
	}

	@action
	setAbortRenewals(abortRenewals) {
		const bool = castBool(abortRenewals);

		this.abortRenewals = bool;

		dbStore.sendToDB({ abortRenewals: bool });
	}

	@action
	setThirdSlotFactor(factor) {
		this.thirdSlotFactor = factor;

		dbStore.sendToDB({ thirdSlotFactor: factor });
	}

	@action
	setThirdSlot(thirdSlot) {
		const bool = castBool(thirdSlot);

		this.thirdSlot = bool;

		dbStore.sendToDB({ thirdSlot: bool });
	}

	@action
	setCpaType(type) {
		this.cpaType = type;
		dbStore.sendToDB({ cpaType: type });
	}

	@action
	setBillingMethod(method) {
		this.billingMethod = method;
		dbStore.sendToDB({ billingMethod: method });
	}

	@action
	setCpa(cpa) {
		this.cpa = cpa;

		dbStore.sendToDB({ cpa });
	}

	@action
	setTagline(tagline) {
		this.tagline = tagline;

		dbStore.sendToDB({ tagline });
	}

	@action
	setOfferTerms(terms) {
		this.offerTerms = terms;

		dbStore.sendToDB({ offerTerms: terms });
	}

	@action
	setOfferLink(link) {
		this.offerLink = link;

		dbStore.sendToDB({ offerLink: addProtocolToLink(link) });
	}

	@action
	setOfferType(type) {
		this.offerType = type;
		dbStore.sendToDB({ offerType: type });
	}

	@action
	setOfferAmount(amount) {
		this.offerAmount = amount;
		dbStore.sendToDB({ offerAmount: amount });
	}

	@action
	setOfferCodeSource(source) {
		this.offerCodeSource = source;
		dbStore.sendToDB({ offerCodeSource: source });
	}

	@action
	setOfferGlobalCode(code) {
		this.offerGlobalCode = code;
		dbStore.sendToDB({ offerGlobalCode: code });
	}

	@action
	setOfferManagedCode(code) {
		this.offerManagedCode = code;
		dbStore.sendToDB({ offerManagedCode: code });
	}

	@action
	setShopifyShop(shop) {
		this.shopifyShop = shop;
		dbStore.sendToDB({ shopifyShop: shop });
	}

	@action
	setPlatformSelection(platform) {
		this.platformSelection = platform;
		dbStore.sendToDB({ platformSelection: platform });
	}

	@action
	setReviewStarted(started) {
		this.reviewStarted = started;
		dbStore.sendToDB({ reviewStarted: started });
	}

	@action
	setIntegration(obj, platformOverride) {
		//this.integrated = true;
		//dbStore.sendToDB({ integrated: true });
		const platform = platformOverride || this.platformSelection;

		if (!platform) {
			console.warn('No platform to set integration');
			return;
		}

		dbStore.setBrandIntegration(obj, platform);
	}

	@action
	setIntegrated(val) {
		this.integrated = val;
	}

	@action
	setName(name) {
		this.name = name;
		dbStore.sendToDB({ name });
	}

	@action
	setLogo(file) {
		this.logo = file;
		dbStore.sendToStorage({ file, type: 'logo' });
	}

	@action
	setPrimaryColor(color) {
		this.primaryColor = color;
		dbStore.sendToDB({ primaryColor: color });
	}

	@action
	setHighlightColor(color) {
		this.highlightColor = color;
		dbStore.sendToDB({ highlightColor: color });
	}

	@action
	setBackgroundColor(color) {
		this.backgroundColor = color;
		dbStore.sendToDB({ backgroundColor: color });
	}

	@action
	setEmailDisplayName(name) {
		this.emailDisplayName = name;

		dbStore.sendToDB({ emailDisplayName: name });
	}

	@action
	setEmailReplyTo(email) {
		this.emailReplyTo = email;

		dbStore.sendToDB({ emailReplyTo: email });
	}

	@action
	setBrandFilterType(type) {
		this.brandFilterType = type;

		dbStore.sendToDB({ brandFilterType: type });
	}

	@action
	setDemoGender(type) {
		this.demoGender = type;

		dbStore.sendToDB({ demoGender: type });
	}

	@action
	setAdminProxyShop(shopifyUrl) {
		this.adminProxyShop = shopifyUrl;

		dbStore.sendToDB({ adminProxyShop: shopifyUrl });
	}

	@action
	setAdminShop(val) {
		this.adminShop = val;

		dbStore.sendToDB({ adminShop: val });
	}

	@computed
	get essentialsCollected() {
		const conditions = [
			this.name,
			this.logo,
			this.platformSelection,
			this.tagline,
			this.offerCodeSource,
			this.offerLink,
			this.addressDetail,
			this.addressCity,
			this.addressState
		];

		if (this.addressCountry !== 'CA') {
			conditions.push(this.addressZip);
		}

		if (this.offerCodeSource === 'global') {
			conditions.push(this.offerGlobalCode);
		}

		if (this.offerCodeSource === 'auto') {
			conditions.push(this.offerType);
			conditions.push(this.offerAmount);
		}

		return conditions.every((condition) => condition);
	}

	@computed
	get finalized() {
		if (
			!typeof this.cpa === 'number' ||
			!this.brandCategory ||
			!typeof this.thirdslot === 'boolean' ||
			!typeof this.fetchRenewals === 'boolean' ||
			!typeof this.promoteOnly === 'boolean'
		) {
			return false;
		}

		return true;
	}

	@computed
	get readyToGo() {
		const conditions = [
			this.essentialsCollected,
			this.integrated,
			this.reviewStarted
		];

		return conditions.every((condition) => condition);
	}

	signalDBReady = reaction(
		() => this.readyToGo,
		(ready) => {
			dbStore.sendToDB({ brandIsReady: ready }, true);
		}
	);
}

export default new BrandStore();
