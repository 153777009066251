import Select from './Select.typeRenderer';
import Text from './Text.typeRenderer';
import Image from './Image.typeRenderer';
import Range from './Range.typeRenderer';
import Email from './Email.typeRenderer';
import Brand from './Brand.typeRenderer';
import TextArea from './TextArea.typeRenderer';
import DateField from './Date.typeRenderer';

const types = {
	select: Select,
	number: Text,
	text: Text,
	image: Image,
	range: Range,
	email: Email,
	brand: Brand,
	textarea: TextArea,
	date: DateField
};

export default types;
