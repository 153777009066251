import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import FormBuilder from '../../formBuilder';

@inject('brandStore')
@observer
class EmailTemplates extends React.Component {
   constructor(props) {
      super(props);

      this.state = {};
   }

   static propTypes = {
      brandStore: PropTypes.object
   };

   render() {
      const { brandStore } = this.props;

      return (
         <div>
            {[ 'tplOffer', 'tplReminder' ].map(type => (
               <div key={type}>
                  <h5>{type === 'tplOffer' ? 'Offer Email' : 'Reminder Email'}</h5>
                  <FormBuilder
                     fields={{
                        emailSubject: {
                           title: 'Subject',
                           type: 'text',
                           required: true,
                           invalidText: 'Please provide a subject line',
                           mobxSetter: val =>
                              brandStore[type === 'tplOffer' ? 'setTplOffer' : 'setTplReminder']({
                                 subject: val
                              }),
                           mobxGetter: () => brandStore[type].subject || '',
                           size: 'long'
                        },
                        emailIntro: {
                           title: 'Intro',
                           type: 'textarea',
                           placeholder: '',
                           invalidText: 'Please provide an intro',
                           mobxSetter: val =>
                              brandStore[type === 'tplOffer' ? 'setTplOffer' : 'setTplReminder']({
                                 intro: val
                              }),
                           mobxGetter: () => brandStore[type].intro || '',
                           required: true,
                           size: 'long'
                        },
                        emailBody: {
                           title: 'Body',
                           type: 'textarea',
                           mobxSetter: val =>
                              brandStore[type === 'tplOffer' ? 'setTplOffer' : 'setTplReminder']({
                                 body: val
                              }),
                           mobxGetter: () => brandStore[type].body || '',
                           required: true,
                           size: 'long'
                        }
                     }}
                  />
               </div>
            ))}
         </div>
      );
   }
}

export default EmailTemplates;
