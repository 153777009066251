import { observable, flow, action, reaction, extendObservable } from 'mobx';
import { auth, db } from '../firebase';
import { captureException } from '../util';

const errorMessages = {
	'auth/invalid-email': 'Please enter a valid e-mail address',
	'auth/user-disabled':
		'Your account has been disabled. Please contact us if this is in error.',
	'auth/user-not-found': 'Incorrect e-mail address or password',
	'auth/wrong-password': 'Incorrect e-mail address or password',
	'auth/email-already-in-use':
		'An account already exists with that e-mail address',
	'auth/weak-password': 'Password too weak. Please try a stronger password',
	'auth/operation-not-allowed': 'Operation not allowed'
};

const errorByCode = (code) => {
	return code in errorMessages
		? errorMessages[code]
		: 'An unknown error has occurred. Please try again.';
};

export class AuthStore {
	@observable
	authUser = null;
	@observable
	currentUser = null;
	@observable
	state = '';
	@observable
	errorMessage = '';
	@observable
	signingUp = false;

	constructor() {
		const skipVerify = true; //sessionStorage.getItem('PostPerk.skipVerification') === 'true';
		extendObservable(this, {
			loggedInUser: JSON.parse(
				window.localStorage.getItem('PostPerk.loggedInUser')
			),
			skipVerify
		});
		skipVerify && console.info('Skipping e-mail verification...');
	}

	@action
	setAuthUser = (authUser) => {
		this.authUser = authUser;
	};

	@action
	setCurrentUser(info) {
		this.currentUser = info;
	}

	@action
	clearErrors() {
		this.errorMessage = '';
		this.state = '';
	}

	@action
	setUserLoggedIn(obj) {
		this.loggedInUser = obj;
	}

	@action
	updateEmailVerified(value) {
		this.loggedInUser.emailVerified = value;
	}

	@action
	handleError(e) {
		if (!e.code || !(e.code in errorMessages)) {
			captureException(e);
		}
		this.errorMessage = errorByCode(e.code);
		this.state = 'error';
	}

	cacheLoggedInUser = reaction(
		() => this.authUser,
		(user) => {
			if (user) {
				const smallUser = {
					id: user.uid,
					email: user.email,
					emailVerified: this.skipVerify ? true : user.emailVerified
				};
				this.setUserLoggedIn(smallUser);
				window.localStorage.setItem(
					'PostPerk.loggedInUser',
					JSON.stringify(smallUser)
				);
			} else {
				console.info('Signed out');
				this.setUserLoggedIn(false);
				window.localStorage.setItem('PostPerk.loggedInUser', false);
				if (this.state !== 'error') {
					console.info('Redirecting to login');
					window.location.href = `${window.location.origin}/login`;
				}
			}
		}
	);

	updateCurrentUser = reaction(
		() => this.authUser,
		async (user) => {
			if (!user) {
				this.curentUser = null;
				return;
			}

			this.setCurrentUser(await db.getUserInfo(user.uid));
		}
	);

	reloadUser = flow(function* () {
		this.state = 'pending';

		try {
			yield this.authUser.reload();
		} catch (e) {
			e.message = `Error reloading user: ${e.message}`;
			this.handleError(e);
			return;
		}

		this.state = '';

		return this.authUser;
	});

	logIn = flow(function* (email, password) {
		this.state = 'pending';
		let response;
		try {
			response = yield auth.doSignInWithEmailAndPassword(email, password);
		} catch (e) {
			e.message = `Error logging in: ${e.message}`;
			this.handleError(e);
			return;
		}

		this.state = '';
		return response;
	});

	sendEmailVerification = flow(function* () {
		this.state = 'pending';

		try {
			yield auth.doSendEmailVerification(this.authUser);
		} catch (e) {
			e.message = `Error sending email verification: ${e.message}`;
			this.handleError(e);

			return;
		}
		this.state = '';
	});

	signUp = flow(function* ({
		email,
		password,
		companyName,
		fullName,
		planType
	}) {
		this.state = 'pending';
		this.signingUp = true;
		let authUser;

		try {
			authUser = yield auth.doCreateUserWithEmailAndPassword(
				email,
				password
			);

			yield db.doCreateUser({
				id: authUser.uid,
				email,
				password,
				companyName,
				fullName,
				planType
			});

			if (!this.skipVerify) {
				yield auth.doSendEmailVerification(authUser);
			}

			this.state = '';
		} catch (e) {
			e.message = `Error signing up: ${e.message}`;
			this.handleError(e);

			if (authUser) {
				const id = authUser.uid;
				console.info('Error encountered, deleting created user');
				yield authUser.delete();
				console.info(`Deleted user ${id}`);
			}
		}

		this.signingUp = false;
		return authUser;
	});

	signOut = flow(function* () {
		this.state = 'pending';

		try {
			yield auth.doSignOut();
			this.state = '';
		} catch (e) {
			e.message = `Error signing out: ${e.message}`;
			this.handleError(e);
		}
	});

	sendPasswordReset = flow(function* (email) {
		this.state = 'pending';
		try {
			yield auth.doPasswordReset(email);
			this.state = '';
		} catch (e) {
			e.message = `Error sending password reset: ${e.message}`;
			this.handleError(e);

			return { error: true };
		}
	});

	// @action Change Password
	changePassword = flow(function* ({ password, oldPassword }) {
		this.state = 'pending';
		const email = this.loggedInUser.email;

		try {
			yield auth.doReauthenticate(email, oldPassword);
			yield auth.doPasswordUpdate(password);
			this.state = '';
		} catch (e) {
			e.message = `Error changing password: ${e.message}`;

			throw e;
		}
	});
}

export default new AuthStore();
