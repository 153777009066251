import React from 'react';

import styles from '../css/Loader.module.css';

class Loader extends React.Component {
   render() {
      return (
         <div className={styles.loader}>
            <div className={styles.loaderR1} />
            <div className={styles.loaderR2} />
            <div className={styles.loaderR3} />
            <div className={styles.loaderR4} />
            <div className={styles.loaderR5} />
         </div>
      );
   }
}
export default Loader;
