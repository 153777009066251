import React from 'react';
import PropTypes from 'prop-types';

import { Col, Input } from 'reactstrap';

import styles from '../../css/FormBuilder.module.css';

const Select = props => {
   const { field, setter, name, value } = props;

   return (
      <Col sm="auto">
         <Input
            disabled={field.disabled}
            className={field.size === 'long' ? styles.longInput : styles.smallInput}
            type="select"
            onChange={e => setter(e.target.value)}
            name={name}
            id={`${name}Select`}
            value={value ? value : Object.keys(field.options)[0]}
            required={field.required ? true : false}
         >
            {Object.keys(field.options).map(option => {
               return (
                  <option key={option} value={option}>
                     {field.options[option]}
                  </option>
               );
            })}
         </Input>

         {field.invalidText && <p className="invalid-feedback">{field.invalidText}</p>}

         {field.noteText && <small className={styles.deets}>{field.noteText}</small>}
      </Col>
   );
};

Select.propTypes = {
   field: PropTypes.object.isRequired,
   setter: PropTypes.func.isRequired,
   name: PropTypes.string.isRequired,
   value: PropTypes.string
};

export default Select;
