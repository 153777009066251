import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import TopContent from './TopContent';
import Sidebar from './Sidebar';
import GlobalIncludes from './GlobalIncludes';
import Loader from './Loader.jsx';

import styles from '../css/Layout.module.css';

const withHeaderAndSidebar = Component => {
   @inject('appStore')
   @observer
   class WithHeaderAndSidebar extends React.Component {
      static propTypes = {
         appStore: PropTypes.object.isRequired
      };

      render() {
         const { appStore } = this.props;
         return (
            <div className={styles.outerContainer}>
               <Sidebar />
               <TopContent />
               <main role="main" className={styles.main}>
                  <div className={styles.content}>
                     <div className={styles.contentInner}>
                        {appStore.initialized && <Component />}
                        {!appStore.initialized && (
                           <div style={{ position: 'relative' }}>
                              <Loader />
                           </div>
                        )}
                     </div>
                  </div>
               </main>
               <GlobalIncludes />
               {/* <DriftWidget showWelcome={true} /> */}
            </div>
         );
      }
   }

   return WithHeaderAndSidebar;
};

export default withHeaderAndSidebar;
