import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/fonts.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Provider } from 'mobx-react';
import Raven from 'raven-js';

// import './vendor/drift';
// import './vendor/raaft';

import * as stores from './stores';

if (process.env.REACT_APP_CONFIG_MODE === 'prod') {
   console.info('Initializing Sentry');
   Raven.config('https://1eaacfa968d1417895b9af7b91826231@sentry.io/1202854').install();
}

ReactDOM.render(
   <Provider {...stores}>
      <App />
   </Provider>,
   document.getElementById('root')
);
unregister();
