import { observable, action, reaction, computed, flow } from 'mobx';

import { db, storage } from '../firebase';

import authStore from './authStore';
import appStore from './appStore';
import brandStore from './brandStore';

import { captureException } from '../util';

const updateActions = {
	name: (name) => {
		brandStore.setName(name);
	},
	tagline: (tagline) => {
		brandStore.setTagline(tagline);
	},
	shopifyShop: (shop) => {
		brandStore.setShopifyShop(shop);
	},
	primaryColor: (color) => {
		brandStore.setPrimaryColor(color);
	},
	highlightColor: (color) => {
		brandStore.setHighlightColor(color);
	},
	platformSelection: (platform) => {
		brandStore.setPlatformSelection(platform);
	},
	integrated: (integration) => {
		brandStore.setIntegrated(integration);
	},
	reviewStarted: (started) => {
		brandStore.setReviewStarted(started);
	},
	emailDisplayName: (name) => {
		brandStore.setEmailDisplayName(name);
	},
	emailReplyTo: (email) => {
		brandStore.setEmailReplyTo(email);
	},
	logoImageUrl: async (url) => {
		const result = await fetch(url);
		brandStore.setLogo(await result.blob());
	},
	offerType: (type) => {
		brandStore.setOfferType(type);
	},
	offerTerms: (terms) => {
		brandStore.setOfferTerms(terms);
	},
	offerAmount: (amount) => {
		brandStore.setOfferAmount(amount);
	},
	offerLink: (link) => {
		brandStore.setOfferLink(link);
	},
	offerCodeSource: (source) => {
		brandStore.setOfferCodeSource(source);
	},
	offerGlobalCode: (code) => {
		brandStore.setOfferGlobalCode(code);
	},
	offerManagedCode: (code) => {
		brandStore.setOfferManagedCode(code);
	},
	cpaType: (type) => {
		brandStore.setCpaType(type);
	},
	cpa: (cpa) => {
		brandStore.setCpa(cpa);
	},
	thirdSlot: (thirdSlot) => {
		brandStore.setThirdSlot(thirdSlot);
	},
	thirdSlotFactor: (thirdSlotFactor) => {
		brandStore.setThirdSlotFactor(thirdSlotFactor);
	},
	fetchRenewals: (fetchRenewals) => {
		brandStore.setFetchRenewals(fetchRenewals);
	},
	promoteOnly: (promoteOnly) => {
		brandStore.setPromoteOnly(promoteOnly);
	},
	productType: (productType) => {
		brandStore.setProductType(productType);
	},
	brandCategory: (category) => {
		brandStore.setBrandCategory(category);
	},
	active: (active) => {
		brandStore.setActive(active);
	},
	cardImageUrl: async (url) => {
		const result = await fetch(url);
		brandStore.setCardImage(await result.blob());
	},
	blacklist: (list) => {
		brandStore.setBlacklist(list);
	},
	whitelist: (list) => {
		brandStore.setWhitelist(list);
	},
	brandFilterType: (type) => {
		brandStore.setBrandFilterType(type);
	},
	codeBlacklist: (blacklist) => {
		brandStore.setCodeBlacklist(blacklist);
	},
	demoGender: (type) => {
		brandStore.setDemoGender(type);
	},
	addressDetail: (addr) => {
		brandStore.setBrandAddress(addr);
	},
	addressCity: (city) => {
		brandStore.setBrandCity(city);
	},
	addressState: (state) => {
		brandStore.setBrandState(state);
	},
	addressZip: (zip) => {
		brandStore.setBrandZip(zip);
	},
	addressCountry: (country) => {
		brandStore.setBrandCountry(country);
	},
	customerDataCleanup: (val) => {
		brandStore.setCustomerDataCleanup(val);
	},
	emailMethod: (val) => {
		brandStore.setEmailMethod(val);
	},
	emailSchedule: (val) => {
		brandStore.setEmailSchedule(Object.keys(val)[0], Object.values(val)[0]);
	},
	ratioThreshold: (val) => {
		brandStore.setRatioThreshold(val);
	},
	docId: (val) => {
		brandStore.setBrandId(val);
	},
	tplOffer: (tmpl) => {
		brandStore.setTplOffer(tmpl);
	},
	tplReminder: (tmpl) => {
		brandStore.setTplReminder(tmpl);
	},
	primaryPartner: (brandId) => {
		brandStore.setPrimaryPartner(brandId);
	},
	offerSortFactor: (val) => {
		brandStore.setOfferSortFactor(val);
	},
	abortRenewals: (val) => {
		brandStore.setAbortRenewals(val);
	},
	supportEmail: (val) => {
		brandStore.setBrandSupportEmail(val);
	},
	freeConversionsUntil: (val) => {
		brandStore.setFreeConversionsUntil(val);
	},
	payoutViaAffiliate: (val) => {
		brandStore.setPayoutViaAffiliate(val);
	},
	tags: (val) => {
		brandStore.setTags(val);
	},
	adminProxyShop: (val) => {
		brandStore.setAdminProxyShop(val);
	},
	adminShop: (val) => {
		brandStore.setAdminShop(val);
	},
	billingMethod: (val) => {
		brandStore.setBillingMethod(val);
	},
	allowRepeatEmails: (val) => {
		brandStore.setAllowRepeatEmails(val);
	}
};

export class DBStore {
	@observable
	saving = false;

	@observable
	syncing = false;

	@observable
	toDatabase = [];

	@observable
	toStorage = [];

	@observable
	brandId = null;

	@observable
	initialSyncComplete = false;

	@observable
	displayErrorState = false;

	@computed
	get appInitialized() {
		return !!authStore.authUser && this.initialSyncComplete;
	}

	updateAppStore = reaction(
		() => this.appInitialized,
		async (initialized) => {
			appStore.setInitialized(initialized);
		}
	);

	@action
	setBrandId(id) {
		this.brandId = id;
	}

	@action
	sendToDB(action, force) {
		if ((!this.syncing && this.initialSyncComplete) || force) {
			this.toDatabase.push(action);
		}
	}

	@action
	setDisplayErrorState(val) {
		this.displayErrorState = val;
	}

	@action
	sendToStorage(action) {
		if (!this.syncing && this.initialSyncComplete) {
			this.toStorage.push(action);
		}
	}

	@action
	setSavingBool(value) {
		this.saving = value;
	}

	@action
	setInitialSyncComplete(value) {
		this.initialSyncComplete = value;
	}

	@action
	clearToDatabase() {
		this.toDatabase.length = 0;
	}

	@action
	clearToStorage() {
		this.toStorage.length = 0;
	}

	@computed
	get readyToSync() {
		// Only sync once firebase is logged in and sign-up steps are complete
		return (
			!!authStore.authUser &&
			!authStore.signingUp &&
			authStore.state !== 'error' &&
			!!authStore.currentUser
		);
	}

	setBrandIntegration = flow(function* (obj, platform) {
		console.info('Started setting brand integration');
		this.setSavingBool(true);

		try {
			yield db.setBrandIntegration(this.brandId, platform, obj);
			this.setSavingBool(false);
			console.info('Finished setting brand integration');
		} catch (e) {
			e.message = `Error setting brand integration: ${e.message}`;
			captureException(e);
		}
	});

	fetchBrandDetail = flow(function* () {
		return yield db.getUserBrandData(
			authStore.authUser.uid,
			authStore.currentUser.admin
		);
	});

	syncDbToStore = flow(function* () {
		console.info('Started syncing');
		this.syncing = true;

		try {
			const ignoreProps = [
				'ownerId',
				'postCount',
				'updatedAt',
				'nameModifier',
				'brandIsReady',
				'brandId',
				'logos',
				'backgrounds',
				'counts',
				'documentCreatedAt',
				'activatedAt',
				'tests',
				'integration',
				'tplSwap'
			];

			const brandData = yield db.getUserBrandData(
				authStore.authUser.uid,
				authStore.currentUser.admin
			);

			console.info('Retrieved brand data');

			// Saving brand ID to store
			this.setBrandId(brandData.docId);

			// Calling update action for every property in merged brand data
			const updateResults = Object.keys(brandData)
				.filter((prop) => !ignoreProps.includes(prop))
				.filter((property) => {
					const updateActionExists = property in updateActions;
					if (!updateActionExists) {
						console.warn(
							`Possible missing update action for "${property}" property`
						);
					}

					return updateActionExists;
				})
				.map((property) =>
					Promise.resolve(
						updateActions[property](brandData[property])
					)
				);

			// Waiting for any async update actions
			yield Promise.all(updateResults);

			this.syncing = false;
			console.info('Finished syncing');
		} catch (e) {
			e.message = `Error with store sync: ${e.message}`;
			captureException(e);
			this.setDisplayErrorState(true);
		}
	});

	initialSync = reaction(
		() => this.readyToSync,
		async (rdy, syncReaction) => {
			syncReaction.dispose();
			if (!rdy) {
				console.info('Aborting sync');
				return;
			}

			await this.syncDbToStore();
			this.setInitialSyncComplete(true);
		}
	);

	syncChangesToDB = reaction(
		() => this.toDatabase.length,
		async (len) => {
			if (len === 0) {
				return;
			}

			this.setSavingBool(true);

			console.info(`Saving ${len} changes to DB`);
			try {
				await db.doUpdateBrand(
					this.brandId,
					this.toDatabase.reduce(
						(obj, change) => Object.assign(obj, change),
						{}
					)
				);
				this.clearToDatabase();
			} catch (e) {
				e.message = `Error syncing changes to db: ${e.message}`;
				captureException(e);
			}

			this.setSavingBool(false);
			console.info('Done saving to DB');
		},
		// Debouncing delay set to catch concurrent changes and consolidate into one request
		{ delay: 10 }
	);

	syncChangesToStorage = reaction(
		() => this.toStorage.length,
		async (len) => {
			if (len === 0) {
				return;
			}

			this.setSavingBool(true);

			console.info(`Saving ${len} files to Storage`);
			try {
				// Upload image into Storage
				const results = await Promise.all(
					this.toStorage.map(
						storage.uploadImage.bind(storage, this.brandId)
					)
				);

				// Upload URL for image into DB with brand info
				results.forEach(({ type, url }) =>
					this.sendToDB({
						[`${type}ImageUrl`]: url
					})
				);
				this.clearToStorage();
			} catch (e) {
				e.message = `Error syncing changes to storage: ${e.message}`;
				captureException(e);
			}

			this.setSavingBool(false);
			console.info('Done saving to Storage');
		},
		{ delay: 10 }
	);
}

export default new DBStore();
