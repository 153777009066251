import { action, observable } from 'mobx';

export class AppStore {
   @observable initialized = false;
   @observable previewingBrand = false;

   @action
   setInitialized(value) {
      this.initialized = value;
   }

   @action
   setPreviewingBrand(value) {
      this.previewingBrand = value;
   }
}

export default new AppStore();
