import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import _ from 'lodash';

import SaveButton from '../../components/SaveButton';

import styles from '../../css/AdminCodeBlacklist.module.css';

@inject('brandStore')
@observer
class AdminCodeBlacklist extends React.Component {
   static propTypes = {
      brandStore: PropTypes.object
   };

   constructor(props) {
      super(props);

      const blacklist = [ ...props.brandStore.codeBlacklist ];

      if (!blacklist.length) {
         blacklist.push('');
      }

      this.state = { blacklist };
   }

   handleAddClick() {
      const newList = [ ...this.state.blacklist ];
      newList.push('');

      this.setState({
         blacklist: newList
      });
   }

   handleFieldChange(code, index) {
      const newList = [ ...this.state.blacklist ];

      newList[index] = code;

      this.setState({
         blacklist: newList
      });
   }

   handleDelClick(code, index) {
      if (!!code && !window.confirm(`Are you sure you want to unblacklist ${code}?`)) {
         return;
      }

      const newList = [ ...this.state.blacklist ];

      newList.splice(index, 1);

      if (!newList.length) {
         newList.push('');
      }

      this.setState({
         blacklist: newList
      });
   }

   renderField(code, index) {
      const { blacklist } = this.state;

      return (
         <FormGroup key={index} className={styles.group}>
            <Input
               className={styles.codeInput}
               type="text"
               name={`code${index}`}
               id={`codeField${index}`}
               value={code}
               placeholder="Enter code"
               onChange={e => this.handleFieldChange(e.target.value, index)}
            />

            <Button
               onClick={e => {
                  e.preventDefault();
                  this.handleDelClick(code, index);
               }}
               outline
               size="sm"
               className={styles.delBtn}
               color="danger"
            >
               delete
            </Button>

            {blacklist.length == index + 1 && (
               <Button
                  onClick={e => {
                     e.preventDefault();
                     this.handleAddClick();
                  }}
                  className={styles.addBtn}
                  color="primary"
                  size="sm"
               >
                  +
               </Button>
            )}
         </FormGroup>
      );
   }

   handleFormSubmit(e) {
      e && e.preventDefault();
      this.submitFormData();
   }

   async submitFormData() {
      this.props.brandStore.setCodeBlacklist(this.state.blacklist.filter(item => !!item));
   }

   formDataChanged() {
      const { brandStore } = this.props;
      const { blacklist } = this.state;

      return !_.isEqual(
         _.sortBy(brandStore.codeBlacklist),
         _.sortBy(blacklist.filter(item => !!item))
      );
   }

   render() {
      const { blacklist } = this.state;

      return (
         <div className={styles.container}>
            <p className={styles.introText}>
               Adding past discount codes here will suppress any future associated conversions.
            </p>
            <Form>{blacklist.map((...args) => this.renderField(...args))}</Form>
            <SaveButton
               onSubmitHandler={e => this.handleFormSubmit(e)}
               enableCondition={this.formDataChanged()}
            />
         </div>
      );
   }
}

export default AdminCodeBlacklist;
