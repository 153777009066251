import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

console.info(`Using config: ${process.env.REACT_APP_CONFIG_MODE}`);

if (!firebase.apps.length) {
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_API_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_DB_URL,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET
	});
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const firestore = firebase.firestore;

export { auth, db, storage, EmailAuthProvider, firestore };
