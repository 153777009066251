import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import '../css/wizard.css';

@inject('wizardStore')
@observer
class Wizard extends React.Component {
	static propTypes = {
		wizardStore: PropTypes.object.isRequired
	};

	render() {
		const { wizardStore } = this.props;

		if (!wizardStore.visible) {
			return null;
		}

		const { StepComponent } = wizardStore;

		const currentStep = wizardStore.steps[wizardStore.step];

		const progress = Math.round(
			((wizardStore.step + 1) / wizardStore.steps.length) * 100
		);
		const firstPage = wizardStore.step === 0;
		const nextDisabled = false;

		return (
			<Modal
				size="lg"
				isOpen={wizardStore.visible}
				toggle={() => wizardStore.toggle()}
			>
				<ModalHeader className="wizard-modal-header pt-2 pb-2">
					{currentStep.title}
					<div className="progress mt-1">
						<div
							className="progress-bar"
							role="progressbar"
							style={{ width: `${progress}%` }}
							aria-valuenow={progress}
							aria-valuemin="0"
							aria-valuemax="100"
						>
							{progress}%
						</div>
					</div>
				</ModalHeader>
				<ModalBody className="wizard-modal-body pb-2">
					{wizardStore.visible && (
						<StepComponent
							advance={() => wizardStore.advance()}
							wizard={true}
						/>
					)}
				</ModalBody>
				<ModalFooter className="wizard-footer">
					<Button
						disabled={firstPage}
						onClick={() => wizardStore.handlePreviousClick()}
						color={firstPage ? 'secondary' : 'primary'}
						tabIndex="-1"
					>
						Previous
					</Button>
					<Button
						onClick={() => {
							if (wizardStore.currentStep.type === 'standard') {
								wizardStore.advance();
								return;
							}

							wizardStore.nextClickEmitter.dispatchEvent(
								new CustomEvent('click')
							);
						}}
						color={nextDisabled ? 'secondary' : 'primary'}
						disabled={nextDisabled}
					>
						{wizardStore.finalPage
							? wizardStore.finalNextButtonText
							: wizardStore.nextButtonText}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default Wizard;
