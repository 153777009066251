import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Input, Label, Form, FormGroup, Button } from 'reactstrap';

import { observer, inject } from 'mobx-react';

import styles from '../css/OrderIntegration.module.css';

@inject('brandStore', 'dbStore', 'wizardStore')
@observer
class ShopifyIntegrationModal extends React.Component {
	state = {
		shop: '',
		inputInvalid: false
	};

	static propTypes = {
		brandStore: PropTypes.object.isRequired,
		dbStore: PropTypes.object.isRequired,
		wizardStore: PropTypes.object.isRequired
	};

	async componentDidMount() {
		const { wizardStore } = this.props;

		this.addRechargeStep();

		// Listen for next clicks via wizard
		wizardStore.nextClickEmitter.addEventListener('click', () => {
			this.setShopifyShop();
			this.props.wizardStore.advance();
		});
	}

	setShopifyShop() {
		const { brandStore } = this.props;
		const shop = this.shopClean;
		if (shop) {
			brandStore.setShopifyShop(this.shopClean);
		}
	}

	addRechargeStep() {
		const { wizardStore, brandStore } = this.props;
		const step = {
			id: 'rechargeStep',
			title: 'Recharge Access',
			type: 'builder',
			fields: ['rechargeToken']
		};

		if (brandStore.platformSelection === 'recharge') {
			wizardStore.addStep(step);
		} else {
			wizardStore.removeStep(step.id);
		}
	}

	shopIsValid() {
		const { shop } = this.state;
		return shop && ~shop.indexOf('myshopify.com');
	}

	get shopClean() {
		const { shop } = this.state;

		return shop
			.replace('http://', '')
			.replace('https://', '')
			.replace(new RegExp(/\//, 'g'), '')
			.trim();
	}

	get installLink() {
		return `${process.env.REACT_APP_SHOPIFY_INSTALL}?shop=${this.shopClean}`;
	}

	render() {
		const { shop, inputInvalid } = this.state;

		return (
			<div>
				<p>
					Almost there! You indicated that you're using{' '}
					<strong>Shopify</strong> as your eCommerce platform.
					Fortunately all you need to do is install our Shopify App.
				</p>

				<Form onSubmit={(e) => e.preventDefault()}>
					<FormGroup>
						<Label>Please provide your shopify domain:</Label>
						<Input
							type="text"
							value={shop}
							placeholder="example.myshopify.com"
							required
							onChange={(e) =>
								this.setState({
									shop: e.target.value
								})
							}
						/>

						{inputInvalid && (
							<p className={styles.invalidInput}>
								You must provide your domain, ending in
								'myshopify.com'
							</p>
						)}

						<CopyToClipboard
							text={this.installLink}
							onCopy={() => this.setState({ copied: true })}
						>
							<Button
								className={styles.copyBtn}
								color="info"
								size="lg"
								disabled={this.shopIsValid() ? false : true}
							>
								Copy Install Link
							</Button>
						</CopyToClipboard>
					</FormGroup>
				</Form>
			</div>
		);
	}
}
export default ShopifyIntegrationModal;
