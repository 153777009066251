import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';

@inject('dbStore')
@observer
class SaveButton extends React.Component {
   static propTypes = {
      enableCondition: PropTypes.bool.isRequired,
      onSubmitHandler: PropTypes.func.isRequired,
      className: PropTypes.string,
      dbStore: PropTypes.object.isRequired,
      disableWrapper: PropTypes.bool,
      displayText: PropTypes.string
   };

   render() {
      const {
         enableCondition,
         onSubmitHandler,
         className,
         dbStore,
         disableWrapper,
         displayText
      } = this.props;

      const SaveButton = props => (
         <Button
            size="lg"
            type="button"
            disabled={!enableCondition && !dbStore.syncing}
            onClick={e => onSubmitHandler(e)}
            color={enableCondition || dbStore.saving ? 'primary' : 'secondary'}
         >
            {displayText ? displayText : props.saving ? 'Saving' : 'Save'}
         </Button>
      );

      if (disableWrapper) {
         return <SaveButton saving={dbStore.saving} />;
      }

      return (
         <div className={`d-flex justify-content-end mt-3 ${className ? className : ''}`}>
            <SaveButton saving={dbStore.saving} />
         </div>
      );
   }
}

export default SaveButton;
