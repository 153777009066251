import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

const withLoginRedirects = Component => {
   class WithLoginRedirects extends React.Component {
      static propTypes = {
         authStore: PropTypes.object.isRequired,
         history: PropTypes.object.isRequired
      };

      render() {
         const { authStore } = this.props;
         const user = this.props.authStore.loggedInUser;

         if (authStore.state === 'error' || authStore.signingUp) {
            return <Component />;
         }

         if (user) {
            return <Redirect to="/" />;
         }

         return <Component />;
      }
   }

   return compose(
      withRouter,
      inject('authStore'),
      observer
   )(WithLoginRedirects);
};

export default withLoginRedirects;
