import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { resolveIntegration } from '../formBuilder';
import withWizardToBuilder from './withWizardToBuilder';
import ShopifyIntegration from './IntegrationShopify';
import IntegrationFallback from './IntegrationFallback';

@inject('brandStore')
@observer
class OrderIntegrationModal extends React.Component {
	static propTypes = {
		brandStore: PropTypes.object.isRequired,
		wizard: PropTypes.bool,
		advance: PropTypes.func
	};

	render() {
		const { brandStore } = this.props;

		if (['shopify', 'recharge'].includes(brandStore.platformSelection)) {
			return <ShopifyIntegration {...this.props} />;
		}

		const fields = resolveIntegration(brandStore.platformSelection);

		if (!fields) {
			return <IntegrationFallback />;
		}

		const Component = compose(withWizardToBuilder)({
			title: `${brandStore.platformSelection} Integration`,
			type: 'builder',
			integrationFields: fields
		});

		return <Component {...this.props} />;
	}
}

export default OrderIntegrationModal;
