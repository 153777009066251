import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Col, Input } from 'reactstrap';

import styles from '../../css/FormBuilder.module.css';

class Image extends Component {
	static propTypes = {
		field: PropTypes.object.isRequired,
		setter: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		value: PropTypes.object,
		builderState: PropTypes.object
	};

	render() {
		const { field, setter, name, value, builderState } = this.props;

		return (
			<Col sm="auto">
				{
					{
						true: (
							<div>
								<img
									alt="Selected Logo"
									className={styles.imageFieldPreview}
									src={
										builderState[name]
											? window.URL.createObjectURL(
													builderState[name]
											  )
											: 'nope'
									}
								/>
								<a
									href="#"
									className={styles.imageFieldChangeLink}
									onClick={(e) => {
										e.preventDefault();
										setter(null);
									}}
								>
									Change Image
								</a>
							</div>
						),
						false: (
							<div>
								<Input
									className={
										field.size === 'long'
											? styles.longInput
											: styles.smallInput
									}
									type="file"
									onChange={(e) => setter(e.target.files[0])}
									name={name}
									id={`${name}Input`}
									value={value || ''}
									required={field.required ? true : false}
								/>
								{field.noteText && (
									<small className={styles.deets}>
										{field.noteText}
									</small>
								)}
							</div>
						)
					}[!!builderState[name]]
				}

				{field.invalidText && (
					<p className="invalid-feedback">{field.invalidText}</p>
				)}
			</Col>
		);
	}
}

export default Image;
