import React from 'react';
import PropTypes from 'prop-types';
import FormBuilder, { resolveFields, resolveCollection } from '../formBuilder';

const withBuilder = ({ fields, collection }) => {
   class WithBuilder extends React.Component {
      static propTypes = {
         advance: PropTypes.func,
         wizard: PropTypes.bool,
         wizardStore: PropTypes.object,
         Fallback: PropTypes.func
      };

      render() {
         return (
            <FormBuilder
               fields={collection ? resolveCollection(collection) : resolveFields(fields)}
            />
         );
      }
   }

   return WithBuilder;
};

export default withBuilder;
