import React from 'react';
import PropTypes from 'prop-types';

import styles from '../css/Logo.module.css';

class Logo extends React.Component {
   static propTypes = {
      fontSize: PropTypes.number,
      color: PropTypes.string
   };
   render() {
      const { fontSize, color } = this.props;

      return (
         <span
            style={{
               fontSize: fontSize ? `${fontSize}rem` : 'inherit',
               color: color ? color : 'black'
            }}
            className={styles.container}
         >
            PostPerk<span>.</span>
         </span>
      );
   }
}
export default Logo;
