import React from 'react';
import PropTypes from 'prop-types';
import BrandSelector from '../../components/BrandSelect';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';

import { Col, Input } from 'reactstrap';

import styles from '../../css/FormBuilder.module.css';

@inject('adminStore')
@observer
class Brand extends React.Component {
	static defaultProps = {
		value: ''
	};

	static propTypes = {
		adminStore: PropTypes.object
	};

	constructor(props) {
		super(props);

		this.state = {
			brands: props.value ? props.value.split('|') : []
		};
	}

	addBrand(brandId) {
		const { brands } = this.state;
		const { field } = this.props;

		if (field.max && brands.length >= field.max) {
			alert(
				`You can only select ${field.max} ${
					field.max === 1 ? 'brand' : 'brands'
				}. Remove one first.`
			);
			return;
		}

		if (brands.includes(brandId)) {
			console.info('Already selected');
			return;
		}

		this.setState(
			{
				brands: [...brands, brandId]
			},
			() => {
				this.onValueChange();
			}
		);
	}

	onValueChange() {
		this.props.setter(this.state.brands.join('|'));
	}

	removeBrand(brandId) {
		this.setState(
			{
				brands: _.without(this.state.brands, brandId)
			},
			() => {
				this.onValueChange();
			}
		);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.value && this.props.value) {
			this.setState({
				brands: this.props.value.split('|')
			});
		}
	}

	renderBrand(brandId) {
		if (!brandId) return null;

		const brand = this.props.adminStore.allBrands.find(
			(brand) => brand.docId === brandId
		);

		return (
			<div className={styles.selectedBrand} key={brandId}>
				{brand ? brand.name : 'Unknown'}{' '}
				<button
					type="button"
					onClick={() => this.removeBrand(brandId)}
					className={styles.brandDelBtn}
				>
					x
				</button>
			</div>
		);
	}

	render() {
		const { brands } = this.state;
		const { field, value, name, adminStore } = this.props;

		if (adminStore.loadingBrands) {
			return <p>Loading brands...</p>;
		}

		return (
			<Col sm="auto" fluid="true">
				<BrandSelector
					brandFilter={(brand) =>
						field.brandFilter
							? field.brandFilter(brand, brands)
							: () => true
					}
					onChange={(selected) => this.addBrand(selected.docId)}
				/>

				<Input
					type="text"
					value={value}
					style={{ display: 'none' }}
					name={name}
					required={field.required ? field.required : false}
					onChange={() => {}}
				/>

				{field.invalidText && (
					<p className="invalid-feedback">{field.invalidText}</p>
				)}

				{field.noteText && (
					<small className={styles.deets}>{field.noteText}</small>
				)}

				<section className={styles.selectedBrandsContainer}>
					{brands.map((brand) => this.renderBrand(brand))}
				</section>
			</Col>
		);
	}
}

Brand.propTypes = {
	field: PropTypes.object.isRequired,
	setter: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	builderState: PropTypes.object
};

export default Brand;
