import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import withAuthLoading from './withLoading';

import styles from '../css/Login.module.css';

@withAuthLoading
@inject('authStore')
@observer
class PasswordReset extends React.Component {
   static propTypes = {
      authStore: PropTypes.object.isRequired
   };

   constructor(props) {
      super(props);

      this.state = {
         email: '',
         validated: false,
         sent: false
      };
   }

   validateForm() {
      this.setState({
         validated: true
      });

      return this.form.checkValidity();
   }

   async handleReset(e) {
      e.preventDefault();

      if (!this.validateForm()) {
         return;
      }

      const { email } = this.state;
      const result = await this.props.authStore.sendPasswordReset(email);

      if (result && result.error) {
         return;
      }

      this.setState({
         sent: true
      });
   }

   render() {
      const { email, validated, sent } = this.state;

      if (sent) {
         return (
            <div>
               <p className="text-center">Please check your e-mail and follow the instructions</p>
               <NavLink className="nav-link text-center" to="/login">
                  Go to Login
               </NavLink>
            </div>
         );
      }

      return (
         <form
            ref={form => (this.form = form)}
            className={`${styles.form} ${validated ? 'was-validated' : ''}`}
            onSubmit={e => this.handleLogin(e)}
         >
            <div className="form-group">
               <input
                  name="login-email"
                  type="email"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  className="form-control"
                  placeholder="Enter E-mail Address"
                  required
               />
               <div className="invalid-feedback">Please provide a valid email address</div>
            </div>

            <div className={styles.actions}>
               <button
                  type="submit"
                  onClick={e => this.handleReset(e)}
                  className="btn btn-primary mx-auto"
               >
                  Send Password Reset E-mail
               </button>
            </div>
         </form>
      );
   }
}
export default PasswordReset;
