import { getUserToken } from './auth';

const base = process.env.REACT_APP_CLOUD_FUNCTIONS;

// Creates options object with auth headers
const opts = async (body, authHeaders) => {
	const userToken = await getUserToken();
	const headers = {
		'Content-Type': 'application/json'
	};

	if (authHeaders) {
		headers.Authorization = `Bearer ${userToken}`;
	}

	return {
		method: 'POST',
		headers,
		body
	};
};

const requestWrapper = ({ method, errorMessage }) => {
	return async (body, authHeaders = true) => {
		try {
			const options = await opts(JSON.stringify(body), authHeaders);
			const response = await fetch(`${base}/${method}`, options);

			if (!(response.status >= 200 && response.status < 300)) {
				return { error: await response.text() };
			}

			try {
				return await response.json();
			} catch (e) {
				console.warn(`Error parsing for ${method}, ${e.message}`);
				return '';
			}
		} catch (e) {
			e.message = `${errorMessage}: ${e.message}`;
			throw e;
		}
	};
};

export const getBrand = requestWrapper({
	method: 'getBrand',
	errorMessage: 'Error getting brand'
});

export const getAllBrands = requestWrapper({
	method: 'getAllBrands',
	errorMessage: 'Error getting all brands'
});

export const sendDemoEmail = requestWrapper({
	method: 'sendDemoEmailEndpoint',
	errorMessage: 'Error sending demo email'
});

export const sendTestEmail = requestWrapper({
	method: 'sendTestEmail',
	errorMessage: 'Error sending test email'
});

export const createBrand = requestWrapper({
	method: 'brands',
	errorMessage: 'Error creating brand'
});
