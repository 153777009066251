import { storage } from './firebase';
import moment from 'moment-timezone';

const PST = 'America/Los_Angeles';

export const uploadImage = async (brandId, { file, type }) => {
	try {
		const typeToDirectory = {
			logo: 'logos',
			background: 'backgrounds',
			card: 'cards'
		};

		const now = moment().tz(PST);

		let logoRef = storage
			.ref()
			.child(
				`${typeToDirectory[type]}/${brandId}_${now.format(
					'YYMMDDHHmm'
				)}`
			);

		await logoRef.put(file, {
			contentType: file.type,
			cacheControl: 'max-age=604800'
		});

		return {
			url: `https://${process.env.REACT_APP_STORAGE_BUCKET}/${logoRef.fullPath}`,
			type
		};
	} catch (e) {
		e.message = `Error uploading image: ${e.message}`;
		throw e;
	}
};
