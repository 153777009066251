import React from 'react';
import PropTypes from 'prop-types';

import { Col, CustomInput } from 'reactstrap';

import styles from '../../css/FormBuilder.module.css';

const Select = props => {
   const { field, setter, name, value } = props;

   return (
      <Col sm="auto">
         <div className={styles.rangeInputContainer}>
            <CustomInput
               disabled={field.disabled}
               className={
                  field.size === 'long' ? styles.longInput : styles.smallInput
               }
               type="range"
               onChange={e => setter(e.target.value)}
               name={name}
               id={`${name}Range`}
               value={value || 0}
               required={field.required ? true : false}
               min={field.min}
               max={field.max}
               step={field.step}
            />

            <span className={styles.rangeValue}>{value}</span>
         </div>

         {field.invalidText && (
            <p className="invalid-feedback">{field.invalidText}</p>
         )}

         {field.noteText && (
            <small className={styles.deets}>{field.noteText}</small>
         )}
      </Col>
   );
};

Select.propTypes = {
   field: PropTypes.object.isRequired,
   setter: PropTypes.func.isRequired,
   name: PropTypes.string.isRequired,
   value: PropTypes.string
};

export default Select;
