import { firebase, functions } from './index';
import appStore from '../stores/appStore';
import _ from 'lodash';

const { db, firestore } = firebase;

export const doCreateUser = async ({ id, email, companyName, fullName }) => {
	try {
		const newBrand = await doCreateBrand({
			ownerId: id
		});

		return await db.collection('user').doc(id).set({
			createdAt: firestore.FieldValue.serverTimestamp(),
			email,
			companyName,
			fullName,
			brand: newBrand
		});
	} catch (e) {
		e.message = `Error creating user: ${e.message}`;
		throw e;
	}
};

export const doCreateBrand = async ({ ownerId, name }) => {
	const payload = {
		documentCreatedAt: firestore.FieldValue.serverTimestamp()
	};

	if (name) {
		payload.name = name;
	}

	if (ownerId) {
		payload.ownerId = ownerId;
	}

	return await db.collection('brand').add(payload);
};

export const getUserBrandData = async (userId, isAdmin = false) => {
	try {
		const previewBrand = sessionStorage.getItem(
			'PostPerk.adminPreviewBrand'
		);
		const willPreview = isAdmin && previewBrand;

		const queryString = willPreview
			? `brandId=${previewBrand}`
			: `ownerId=${userId}`;
		const queryUrl = `${process.env.REACT_APP_CLOUD_FUNCTIONS}/getBrand?${queryString}`;

		const result = await fetch(queryUrl);
		const brandDoc = await result.json();

		if (willPreview) {
			appStore.setPreviewingBrand(previewBrand);
		}

		return brandDoc;
	} catch (e) {
		e.message = `Error getting brand data: ${e.message}`;
		throw e;
	}
};

export const getUserInfo = async (userId) => {
	try {
		const userDoc = await db.collection('user').doc(userId).get();

		return {
			...userDoc.data(),
			id: userDoc.id
		};
	} catch (e) {
		e.message = `Error getting user info: ${e.message}`;
		throw e;
	}
};

export const getAllBrands = async (props = 'all', sort = true) => {
	try {
		return await functions
			.getAllBrands()
			.then((docs) => docs.filter((brand) => !!brand.name))
			.then((docs) =>
				sort
					? docs.sort((a, b) => {
							const aName = a.name.toLowerCase();
							const bName = b.name.toLowerCase();

							return aName > bName ? 1 : aName < bName ? -1 : 0;
					  })
					: docs
			)
			.then((docs) =>
				docs.map((doc) => (props === 'all' ? doc : _.pick(doc, props)))
			);
	} catch (e) {
		e.message = `Error getting all brands: ${e.message}`;
		throw e;
	}
};

export const doUpdateBrand = async (brandId, data) => {
	try {
		const onlyReady =
			Object.keys(data).length === 1 && 'brandIsReady' in data;

		return await db
			.collection('brand')
			.doc(brandId)
			.update(
				Object.assign(
					{},
					data,
					onlyReady
						? {}
						: { updatedAt: firestore.FieldValue.serverTimestamp() }
				)
			);
	} catch (e) {
		e.message = `Error updating brand: ${e.message}`;
		throw e;
	}
};

export const updateBrandWhitelist = async (brandId, whitelist) => {
	try {
		return await db.collection('brand').doc(brandId).update({
			whitelist
		});
	} catch (e) {
		e.message = `Error updateBrandWhitelist(): ${e.message}`;
		throw e;
	}
};

export const setBrandIntegration = async (brandId, platform, data) => {
	try {
		return await db
			.collection('brand')
			.doc(brandId)
			.collection('integration')
			.doc(platform)
			.set(Object.assign(data), { merge: true });
	} catch (e) {
		e.message = `Error updating brand integration: ${e.message}`;
		throw e;
	}
};

export const getAllConversions = async () => {
	const results = await db.collection('conversion').get();

	return results.docs.map((doc) => doc.data());
};

export const getAllActiveBrands = async () => {
	const results = await db
		.collection('brand')
		.where('active', '==', true)
		.get();

	return results.docs.map((doc) => ({ ...doc.data(), docId: doc.id }));
};
