import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from '../css/Login.module.css';
import Loader from './Loader.jsx';

const withAuthLoading = (Component, type = 'auth') => {
   @inject('authStore')
   @observer
   class WithAuthLoading extends React.Component {
      static propTypes = {
         authStore: PropTypes.object.isRequired
      };

      state = {
         loading: false
      };

      loaderPromise(promise) {
         this.setState(
            {
               loading: true
            },
            async () => {
               await promise;
               this.setState({ loading: false });
            }
         );
      }

      render() {
         const { authStore } = this.props;
         const { loading } = this.state;

         const pending = type === 'auth' ? authStore.state === 'pending' : loading;

         return (
            <div className={`${styles.loadingContainer} `}>
               {pending && <Loader />}
               <div
                  className={`${styles.componentContainer} ${pending &&
                     styles.componentWhileLoading}`}
               >
                  <Component
                     loaderPromise={promise => this.loaderPromise(promise)}
                     {...this.props}
                  />
               </div>
            </div>
         );
      }
   }

   return WithAuthLoading;
};

export default withAuthLoading;
