import * as Icon from 'react-feather';
import { compose } from 'recompose';

import withBuilder from '../components/withBuilder';

// Control Panel Components
import AppIntro from '../views/AppIntro.jsx';
import EmailTemplates from '../views/Settings/EmailTemplates';

// Login Components
import SignupForm from '../components/SignupForm.jsx';
import LoginForm from '../components/LoginForm.jsx';
import PasswordReset from '../components/PasswordReset';
import ChangePassword from '../components/ChangePassword';
import VerifyEmail from '../components/VerifyEmail';

// Global Admin
import AdminResendEmail from '../views/Admin/AdminResendEmail';

// Admin
import AdminActivate from '../views/Admin/AdminActivate';
import AdminManagePartners from '../views/Admin/AdminManagePartners';
import AdminCodeBlacklist from '../views/Admin/AdminCodeBlacklist';
import AdminSendDemoEmail from '../views/Admin/AdminSendDemoEmail';

export const globalAdminRoutes = [
	{
		path: '/global-resend-email',
		component: AdminResendEmail,
		exact: true,
		title: 'Resend Email',
		icon: Icon.Sliders
	}
];

export const adminRoutes = [
	{
		path: '/admin-offers',
		component: compose(withBuilder)({ collection: 'offerDetails' }),
		exact: true,
		title: 'Offer Details',
		category: 'top',
		icon: Icon.DollarSign
	},
	{
		path: '/admin-finalize',
		component: compose(withBuilder)({ collection: 'finalizeBrand' }),
		exact: true,
		title: 'Finalize Brand',
		icon: Icon.Settings
	},
	{
		path: '/admin-advanced',
		component: compose(withBuilder)({ collection: 'advancedSettings' }),
		exact: true,
		title: 'Advanced Settings',
		icon: Icon.Settings
	},
	{
		path: '/admin-send-email',
		component: AdminSendDemoEmail,
		exact: true,
		title: 'Send Demo Email',
		icon: Icon.Mail
	},
	{
		path: '/admin-partners',
		component: AdminManagePartners,
		exact: true,
		title: 'Manage Partners',
		icon: Icon.Users
	},
	{
		path: '/admin-code-blacklist',
		component: AdminCodeBlacklist,
		exact: true,
		title: 'Code Blacklist',
		icon: Icon.Trash
	},
	{
		path: '/admin-activate',
		component: AdminActivate,
		exact: true,
		title: 'Activate',
		icon: Icon.Zap
	}
];

export const loginRoutes = [
	{
		path: '/login',
		component: LoginForm,
		heading: 'Login',
		redirects: true
	},
	{
		path: '/signup',
		component: SignupForm,
		heading: 'Sign Up',
		redirects: true
	},
	{
		path: '/reset',
		component: PasswordReset,
		heading: 'Reset Password'
	},
	{
		path: '/change-password',
		component: ChangePassword,
		heading: 'Change Password'
	},
	{
		path: '/verify-email',
		component: VerifyEmail,
		heading: 'Verify E-mail Address',
		redirects: false
	}
];

export default [
	{
		path: '/',
		component: AppIntro,
		exact: true,
		title: 'Home',
		category: 'top',
		icon: Icon.Sunset
	},
	{
		path: '/settings-brand',
		component: compose(withBuilder)({ collection: 'brandDetails' }),
		exact: true,
		title: 'Brand Details',
		category: 'top',
		icon: Icon.Home
	},
	{
		path: '/settings-address',
		component: compose(withBuilder)({ collection: 'brandAddress' }),
		exact: true,
		title: 'Brand Address',
		category: 'top',
		icon: Icon.Map
	},
	{
		path: '/settings-templates',
		component: EmailTemplates,
		exact: true,
		title: 'Email Templates',
		category: 'top',
		icon: Icon.Send
	}
];
