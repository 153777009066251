import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import * as Icon from 'react-feather';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import routes, { adminRoutes, globalAdminRoutes } from '../constants/routes';

import Logo from './Logo';

import layoutStyles from '../css/Layout.module.css';
import styles from '../css/Sidebar.module.css';

//@withRouter needed to enforce rerendering on updates
@inject('wizardStore', 'brandStore', 'authStore', 'appStore')
@withRouter
@observer
class Sidebar extends Component {
   static propTypes = {
      wizardStore: PropTypes.shape({
         showModal: PropTypes.func
      }),
      brandStore: PropTypes.object,
      authStore: PropTypes.object,
      appStore: PropTypes.object
   };

   render() {
      const { wizardStore, brandStore, authStore, appStore } = this.props;
      const isAdmin = authStore.currentUser && authStore.currentUser.admin ? true : false;
      const previewing = !!appStore.previewingBrand;

      const LogoLink = () => (
         <NavLink
            exact={true}
            className={`${styles.brand} d-flex flex-row align-items-center justify-content-center my-2`}
            to="/"
         >
            <Logo color="#fff" fontSize={1.3} />{' '}
            <span className={styles.brandSmall}>dashboard</span>
         </NavLink>
      );

      if (!appStore.initialized) {
         return (
            <nav className={`layout-sidebar ${layoutStyles.sidebar}`}>
               <LogoLink />
            </nav>
         );
      }

      return (
         <nav className={`layout-sidebar ${layoutStyles.sidebar}`}>
            <LogoLink />
            <div className="nav-main-routes">
               {!(isAdmin && !previewing) && (
                  <div>
                     <h6 className={styles.sidebarPrimaryHeading}>{brandStore.name}</h6>
                     <ul className="nav flex-column">
                        {routes
                           .filter(route => route.category === 'top')
                           .map((route, index) => {
                              if (route.planSpecific && route.planSpecific !== brandStore.plan) {
                                 return null;
                              }
                              return (
                                 <li key={index}>
                                    <NavLink
                                       exact={route.exact}
                                       className={styles.navLink}
                                       to={route.path}
                                    >
                                       {React.cloneElement(<route.icon />, {
                                          className: `feather ${styles.feather}`
                                       })}
                                       {route.title}
                                    </NavLink>
                                 </li>
                              );
                           })}
                        {/* <li>
                           <a
                              onClick={e => {
                                 e.preventDefault();
                                 wizardStore.showModal();
                              }}
                              className={styles.navLink}
                              href="/"
                           >
                              <Icon.Settings className={`feather ${styles.feather}`} />
                              Setup Wizard
                           </a>
                        </li> */}
                     </ul>
                     {/* <h6 className={styles.sidebarHeading}>Brand</h6>
                     <ul className="nav flex-column mb-2">
                        {routes
                           .filter(route => route.category === 'settings')
                           .map((route, index) => (
                              <li key={index}>
                                 <NavLink
                                    exact={route.exact}
                                    className={styles.navLink}
                                    to={route.path}
                                 >
                                    {React.cloneElement(<route.icon />, {
                                       className: `feather ${styles.feather}`
                                    })}
                                    {route.title}
                                 </NavLink>
                              </li>
                           ))}
                     </ul> */}
                  </div>
               )}

               {isAdmin && previewing && (
                  <div>
                     <h6 className={styles.sidebarHeading}>Admin</h6>
                     <ul className="nav flex-column mb-2">
                        {adminRoutes.map((route, index) => {
                           return (
                              <li key={index}>
                                 <NavLink
                                    exact={route.exact}
                                    className={styles.navLink}
                                    to={route.path}
                                 >
                                    {React.cloneElement(<route.icon />, {
                                       className: `feather ${styles.feather}`
                                    })}
                                    {route.title}
                                 </NavLink>
                              </li>
                           );
                        })}
                     </ul>
                  </div>
               )}

               {isAdmin && (
                  <div className={styles.globalAdminContainer}>
                     <h6 className={styles.sidebarPrimaryHeading}>Global Admin</h6>
                     <ul className="nav flex-column mb-2">
                        {globalAdminRoutes.map((route, index) => {
                           return (
                              <li key={index}>
                                 <NavLink
                                    exact={route.exact}
                                    className={styles.navLink}
                                    to={route.path}
                                 >
                                    {React.cloneElement(<route.icon />, {
                                       className: `feather ${styles.feather}`
                                    })}
                                    {route.title}
                                 </NavLink>
                              </li>
                           );
                        })}
                     </ul>
                  </div>
               )}

               {/* <h6 className={styles.sidebarHeading}>E-mails</h6>
               <ul className="nav flex-column mb-2">
                  {routes
                     .filter(route => route.category === 'emails')
                     .map((route, index) => (
                        <li key={index}>
                           <NavLink exact={route.exact} className={styles.navLink} to={route.path}>
                              {React.cloneElement(<route.icon />, {
                                 className: `feather ${styles.feather}`
                              })}
                              {route.title}
                           </NavLink>
                        </li>
                     ))}
               </ul> */}
            </div>
         </nav>
      );
   }
}

export default Sidebar;
