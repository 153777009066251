import { observable, action } from 'mobx';
import dbStore from './dbStore';

export class TemplateStore {
   @observable welcomeEmailFirstParagraph = '';
   @observable welcomeEmailSecondParagraph = '';

   @observable followupOneFirstParagraph = '';
   @observable followupOneSecondParagraph = '';

   @observable followupTwoFirstParagraph = '';
   @observable followupTwoSecondParagraph = '';

   @observable findRewardFirstParagraph = '';
   @observable findRewardSecondParagraph = '';

   @observable showRewardFirstParagraph = '';
   @observable showRewardSecondParagraph = '';

   @observable superstarFirstParagraph = '';
   @observable superstarSecondParagraph = '';
   @observable superstarPhone = '';
   @observable superstarEmail = '';

   @observable deliverRewardIntro = '';
   @observable automatedDeliveryMessage = '';
   @observable manualDeliveryMessage = '';

   @action
   setDeliverRewardContent(content) {
      this.deliverRewardIntro = content;

      dbStore.sendToDB({ 'tplDeliverReward.intro': content });
   }

   @action
   setAutoDeliveryMessage(message) {
      this.automatedDeliveryMessage = message;

      dbStore.sendToDB({ 'tplDeliverReward.auto': message });
   }

   @action
   setManualDeliveryMessage(message) {
      this.manualDeliveryMessage = message;

      dbStore.sendToDB({ 'tplDeliverReward.manual': message });
   }

   @action
   setSuperstar({ first, second, email, phone }) {
      let toSend = {};
      if (typeof first === 'string') {
         this.superstarFirstParagraph = first;
         toSend['tplSuperstar.first'] = first;
      }
      if (typeof second === 'string') {
         this.superstarSecondParagraph = second;
         toSend['tplSuperstar.second'] = second;
      }
      if (typeof phone === 'string') {
         this.superstarPhone = phone;
         toSend['tplSuperstar.phone'] = phone;
      }
      if (typeof email === 'string') {
         this.superstarEmail = email;
         toSend['tplSuperstar.email'] = email;
      }

      dbStore.sendToDB(toSend);
   }

   @action
   setShowReward({ first, second }) {
      const toDb = {};

      if (typeof first === 'string') {
         this.showRewardFirstParagraph = first;
         toDb['tplShowReward.first'] = first;
      }

      if (typeof second === 'string') {
         this.showRewardSecondParagraph = second;
         toDb['tplShowReward.second'] = second;
      }

      dbStore.sendToDB(toDb);
   }

   @action
   setFindReward({ first, second }) {
      const toDb = {};

      if (typeof first === 'string') {
         this.findRewardFirstParagraph = first;
         toDb['tplFindReward.first'] = first;
      }

      if (typeof second === 'string') {
         this.findRewardSecondParagraph = second;
         toDb['tplFindReward.second'] = second;
      }

      dbStore.sendToDB(toDb);
   }

   @action
   setFollowupTwo({ first, second }) {
      const toDb = {};

      if (typeof first === 'string') {
         this.followupTwoFirstParagraph = first;
         toDb['tplEmailFollowupTwo.first'] = first;
      }

      if (typeof second === 'string') {
         this.followupTwoSecondParagraph = second;
         toDb['tplEmailFollowupTwo.second'] = second;
      }

      dbStore.sendToDB(toDb);
   }

   @action
   setFollowupOne({ first, second }) {
      const toDb = {};

      if (typeof first === 'string') {
         this.followupOneFirstParagraph = first;
         toDb['tplEmailFollowupOne.first'] = first;
      }

      if (typeof second === 'string') {
         this.followupOneSecondParagraph = second;
         toDb['tplEmailFollowupOne.second'] = second;
      }

      dbStore.sendToDB(toDb);
   }

   @action
   setWelcomeEmail({ first, second }) {
      const toDb = {};

      if (typeof first === 'string') {
         this.welcomeEmailFirstParagraph = first;
         toDb['tplWelcome.first'] = first;
      }

      if (typeof second === 'string') {
         this.welcomeEmailSecondParagraph = second;
         toDb['tplWelcome.second'] = second;
      }

      dbStore.sendToDB(toDb);
   }
}

export default new TemplateStore();
