import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';

import { firebase } from '../firebase';

const withAuthentication = Component => {
   class WithAuthentication extends React.Component {
      static propTypes = {
         authStore: PropTypes.object.isRequired
      };

      componentDidMount() {
         const { authStore } = this.props;

         firebase.auth.onAuthStateChanged(authUser => {
            authUser ? authStore.setAuthUser(authUser) : authStore.setAuthUser(null);
         });
      }

      render() {
         return <Component />;
      }
   }

   return inject('authStore')(WithAuthentication);
};

export default withAuthentication;
