import React from 'react';

class WizardIntro extends React.Component {
   validateForm() {
      return true;
   }

   async submitFormData() {}

   render() {
      return (
         <div className="mx-4 intro-text">
            <p>
               In the following steps we'll be gathering some basic information from you. This
               information will be used to generate the e-mails and web experiences for your
               customers.
            </p>
            <p>
               Only the essentials will be gathered, and you can always change any setting in the
               future by accessing it via the left-navigation.
            </p>
            <p>
               All settings are saved when you proceed to the next step, so feel free to close it
               and come back later!
            </p>
         </div>
      );
   }
}

export default WizardIntro;
