import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
//import { NavLink } from 'react-router-dom';

import AppIntroSteps from '../components/AppIntroSteps';

import Logo from '../components/Logo';

import styles from '../css/AppIntro.module.css';

@inject('brandStore', 'appStore', 'authStore')
@observer
class AppIntro extends Component {
   static propTypes = {
      brandStore: PropTypes.object.isRequired,
      appStore: PropTypes.object.isRequired,
      authStore: PropTypes.object.isRequired
   };

   render() {
      const { brandStore, appStore, authStore } = this.props;
      const isAdmin = authStore.currentUser && authStore.currentUser.admin ? true : false;
      const previewing = !!appStore.previewingBrand;

      if (isAdmin && !previewing) {
         return (
            <div>
               <h2 style={{ fontSize: '1.4em' }} className="text-center mt-2">
                  Welcome to <Logo fontSize={1.4} /> Administrator 
               </h2>
               <p
                  className="mt-4"
                  style={{
                     fontSize: '1.25em',
                     textAlign: 'center'
                  }}
               >
                  Select brand above to get started
               </p>
            </div>
         );
      }

      return (
         <div className="d-flex flex-column align-items-center">
            <h2 className="text-center mt-2">
               Welcome to <Logo fontSize={2} />
            </h2>

            {
               {
                  false: (
                     <div>
                        <p
                           className={`mt-2 mb-1 text-muted mx-3 text-center ${styles.welcomeText}`}
                        >
                           It looks like you're not quite setup! Just follow these steps to start
                           reaching new customers:
                        </p>

                        <AppIntroSteps />
                     </div>
                  ),
                  true: (
                     <div>
                        <AppIntroSteps />
                        <div className="mb-3">
                           <p
                              className={`mt-4 mb-1 text-muted mx-3 text-center ${
                                 styles.templateText
                              }`}
                           >
                              Your review has started. We'll contact you shortly.
                           </p>
                        </div>
                     </div>
                  )
               }[brandStore.readyToGo.toString()]
            }
         </div>
      );
   }
}

export default AppIntro;
