import Raven from 'raven-js';
const prod = process.env.REACT_APP_CONFIG_MODE === 'prod';

export const captureException = e => {
   console.error(e);

   if (prod) {
      Raven.captureException(e);
   }
};

export const captureMessage = e => {
   console.log(e);

   if (prod) {
      Raven.captureMessage(e);
   }
};

export const addProtocolToLink = link => {
   return !(~link.indexOf('https://') || ~link.indexOf('http://')) ? `https://${link}` : link;
};
