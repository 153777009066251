import React, { Component } from 'react';

import Wizard from '../components/Wizard';

class GlobalIncludes extends Component {
   render() {
      return (
         <div>
            <Wizard />
         </div>
      );
   }
}

export default GlobalIncludes;
