import brandStore from '../stores/brandStore';
import adminStore from '../stores/adminStore';

const fields = {
	brandAddress: {
		title: 'Address',
		type: 'text',
		placeholder: 'ex. 123 blah rd',
		required: true,
		invalidText: 'Please provide a valid address',
		mobxSetter: (val) => brandStore.setBrandAddress(val),
		mobxGetter: () => brandStore.addressDetail,
		size: 'long'
	},
	brandCity: {
		title: 'City',
		type: 'text',
		placeholder: 'ex. Medford',
		required: true,
		invalidText: 'Please provide a valid city',
		mobxSetter: (val) => brandStore.setBrandCity(val),
		mobxGetter: () => brandStore.addressCity,
		size: 'long'
	},
	brandState: {
		title: 'State',
		type: 'select',
		required: true,
		options: {
			AL: 'Alabama',
			AK: 'Alaska',
			AZ: 'Arizona',
			AR: 'Arkansas',
			CA: 'California',
			CO: 'Colorado',
			CT: 'Connecticut',
			DE: 'Delaware',
			FL: 'Florida',
			GA: 'Georgia',
			HI: 'Hawaii',
			ID: 'Idaho',
			IL: 'Illinois',
			IN: 'Indiana',
			IA: 'Iowa',
			KS: 'Kansas',
			KY: 'Kentucky',
			LA: 'Louisiana',
			ME: 'Maine',
			MD: 'Maryland',
			MA: 'Massachusetts',
			MI: 'Michigan',
			MN: 'Minnesota',
			MS: 'Mississippi',
			MO: 'Missouri',
			MT: 'Montana',
			NE: 'Nebraska',
			NV: 'Nevada',
			NH: 'New Hampshire',
			NJ: 'New Jersey',
			NM: 'New Mexico',
			NY: 'New York',
			NC: 'North Carolina',
			ND: 'North Dakota',
			OH: 'Ohio',
			OK: 'Oklahoma',
			OR: 'Oregon',
			PA: 'Pennsylvania',
			RI: 'Rhode Island',
			SC: 'South Carolina',
			SD: 'South Dakota',
			TN: 'Tennessee',
			TX: 'Texas',
			UT: 'Utah',
			VT: 'Vermont',
			VA: 'Virginia',
			WA: 'Washington',
			WV: 'West Virginia',
			WI: 'Wisconsin',
			WY: 'Wyoming',
			ON: 'Ontario'
		},
		mobxSetter: (val) => brandStore.setBrandState(val),
		mobxGetter: () => brandStore.addressState,
		size: 'long'
	},
	brandZip: {
		title: 'Zip Code',
		type: 'text',
		placeholder: '',
		invalidText: 'Please provide a valid zipcode',
		conditional: (state) => state.brandCountry === 'USA',
		mobxSetter: (val) => brandStore.setBrandZip(val),
		mobxGetter: () => brandStore.addressZip,
		required: true
	},
	brandCountry: {
		title: 'Country',
		type: 'select',
		options: {
			USA: 'United States',
			CA: 'Canada'
		},
		mobxSetter: (val) => brandStore.setBrandCountry(val),
		mobxGetter: () => brandStore.addressCountry,
		required: true,
		size: 'long'
	},
	brandLogo: {
		title: 'Brand Logo',
		type: 'image',
		noteText: 'Top image to be used for offer emails',
		mobxSetter: (val) => brandStore.setLogo(val),
		mobxGetter: () => brandStore.logo,
		required: true,
		size: 'long'
	},
	brandName: {
		title: 'Brand Name',
		type: 'text',
		mobxSetter: (val) => brandStore.setName(val),
		mobxGetter: () => brandStore.name,
		required: true,
		size: 'long'
	},
	platform: {
		title: 'Store Platform',
		type: 'select',
		options: {
			shopify: 'Shopify',
			recharge: 'Recharge via Shopify',
			cratejoy: 'Cratejoy',
			squarespace: 'Squarespace',
			woocommerce: 'WooCommerce',
			stripe: 'Stripe',
			bigcommerce: 'BigCommerce',
			magento: 'Magento',
			lemonstand: 'Lemonstand',
			other: 'Other Platform',
			custom: 'Custom (no platform)'
		},
		mobxSetter: (val) => brandStore.setPlatformSelection(val),
		mobxGetter: () => brandStore.platformSelection,
		required: true,
		size: 'long'
	},

	offerType: {
		title: 'Type of Offer',
		type: 'select',
		options: {
			dollar: 'Dollar',
			percent: 'Percent'
		},
		required: true,
		noteText: 'Dollar type is recommended whenever possible',
		mobxSetter: (val) => brandStore.setOfferType(val),
		mobxGetter: () => brandStore.offerType
	},
	offerAmount: {
		title: 'Offer Amount',
		type: 'number',
		step: '0.01',
		min: 0,
		placeholder: (state) => (state.offerType === 'dollar' ? '$X' : 'X%'),
		invalidText: 'Please provide a valid amount',
		mobxSetter: (val) => brandStore.setOfferAmount(parseFloat(val, 10)),
		mobxGetter: () => brandStore.offerAmount,
		required: true
	},
	offerLink: {
		title: 'Offer Link',
		type: 'text',
		noteText: 'Where should we send customers to redeem your offer?',
		invalidText: 'Please enter a valid link',
		mobxSetter: (val) => brandStore.setOfferLink(val),
		mobxGetter: () => brandStore.offerLink,
		placeholder: 'www.yourbrand.com',
		required: true,
		size: 'longest'
	},
	offerTagline: {
		title: 'Brand Tagline',
		type: 'textarea',
		placeholder: 'Premium widgets delivered to you every month',
		required: true,
		invalidText: 'Please provide a valid tagline',
		mobxSetter: (val) => brandStore.setTagline(val),
		mobxGetter: () => brandStore.tagline,
		size: 'long'
	},
	offerCodeSource: {
		title: 'Offer Code Source',
		type: 'select',
		options: {
			global: 'Global',
			auto: 'Auto',
			managed: 'Managed',
			link: 'Link'
		},
		noteText: 'Where should we get the codes from?',
		invalidText: 'Please select a valid option',
		mobxSetter: (val) => brandStore.setOfferCodeSource(val),
		mobxGetter: () => brandStore.offerCodeSource,
		required: true
	},
	offerGlobalCode: {
		title: 'Code',
		type: 'text',
		invalidText: 'Please enter a valid code',
		mobxSetter: (val) => brandStore.setOfferGlobalCode(val),
		mobxGetter: () => brandStore.offerGlobalCode,
		conditional: (state) => state.offerCodeSource === 'global',
		required: true,
		size: 'long'
	},
	offerSortFactor: {
		title: 'Sort Factor',
		type: 'range',
		mobxSetter: (val) => brandStore.setOfferSortFactor(parseInt(val)),
		mobxGetter: () => brandStore.offerSortFactor.toString(),
		min: 0,
		max: 5,
		step: 1,
		noteText:
			'Optional: Positions brand offer before other brands when greater than zero',
		size: 'long'
	},
	cardImage: {
		title: 'Card Image',
		type: 'image',
		noteText: 'Image to be used for offer emails',
		mobxSetter: (val) => brandStore.setCardImage(val),
		mobxGetter: () => brandStore.cardImage,
		required: true
	},
	demoGender: {
		title: 'Customer Gender',
		type: 'select',
		options: {
			neutral: 'Neutral',
			female: 'Female',
			male: 'Male'
		},
		mobxSetter: (val) => brandStore.setDemoGender(val),
		mobxGetter: () => brandStore.demoGender,
		required: true,
		size: 'small'
	},
	freeConversionsUntil: {
		title: 'Free Conversions Until',
		type: 'date',
		mobxSetter: (val) => brandStore.setFreeConversionsUntil(val),
		mobxGetter: () => brandStore.freeConversionsUntil
	},
	brandCategory: {
		title: 'Brand Category',
		type: 'text',
		placeholder: 'ex. candle subscription',
		required: true,
		noteText: 'Used to describe brand in FOMO widget',
		invalidText: 'Please provide a valid category',
		mobxSetter: (val) => brandStore.setBrandCategory(val),
		mobxGetter: () => brandStore.brandCategory,
		size: 'long'
	},
	supportEmail: {
		title: 'Support Email',
		type: 'email',
		required: false,
		noteText: 'Brand customer support email',
		invalidText: 'Please provide a valid email',
		mobxSetter: (val) => brandStore.setBrandSupportEmail(val.trim()),
		mobxGetter: () => brandStore.supportEmail,
		size: 'long'
	},
	cpaType: {
		title: 'Type of CPA',
		type: 'select',
		options: {
			dollar: 'Dollar',
			percent: 'Percent'
		},
		required: true,
		mobxSetter: (val) => brandStore.setCpaType(val),
		mobxGetter: () => brandStore.cpaType
	},
	cpa: {
		title: 'CPA',
		type: 'number',
		required: true,
		placeholder: (state) => (state.cpaType === 'dollar' ? '$X' : 'X%'),
		min: 0,
		step: '0.01',
		invalidText: 'Please provide a valid CPA',
		mobxSetter: (val) => brandStore.setCpa(val),
		mobxGetter: () => brandStore.cpa
	},
	billingMethod: {
		title: 'Billing Method',
		type: 'select',
		options: {
			shopifyBilling: 'Shopify Billing',
			shareASale: 'Share-A-Sale',
			stripe: 'Stripe'
		},
		required: true,
		mobxSetter: (val) => brandStore.setBillingMethod(val),
		mobxGetter: () => brandStore.billingMethod,
		size: 'long'
	},
	thirdSlot: {
		title: 'Enable Third Slot',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		mobxSetter: (val) => brandStore.setThirdSlot(val),
		mobxGetter: () => (brandStore.thirdSlot ? 'true' : 'false'),
		required: false,
		size: 'small'
	},
	thirdSlotFactor: {
		title: 'Third Slot Factor',
		type: 'number',
		noteText: 'Ratio value against other third slot brands',
		invalidText: 'Please enter a valid number',
		conditional: (state) => state.thirdSlot === 'true',
		mobxSetter: (val) => brandStore.setThirdSlotFactor(val),
		mobxGetter: () => brandStore.thirdSlotFactor,
		required: true
	},
	fetchRenewals: {
		title: 'Fetch Renewal orders',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		noteText: 'Only valid for Cratejoy brands',
		conditional: () => brandStore.platformSelection === 'cratejoy',
		mobxSetter: (val) => brandStore.setFetchRenewals(val),
		mobxGetter: () => (brandStore.fetchRenewals ? 'true' : 'false')
	},
	abortRenewals: {
		title: 'Abort Renewals',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		noteText: 'If true, will ONLY send emails to new customers',
		conditional: () => brandStore.platformSelection !== 'cratejoy',
		mobxSetter: (val) => brandStore.setAbortRenewals(val),
		mobxGetter: () => (brandStore.abortRenewals ? 'true' : 'false')
	},
	dataCleanup: {
		title: 'Remove Customer Emails',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		noteText: 'Remove plain-text emails (only keep hashes)',
		mobxSetter: (val) => brandStore.setCustomerDataCleanup(JSON.parse(val)),
		mobxGetter: () => (brandStore.customerDataCleanup ? 'true' : 'false'),
		required: false,
		size: 'small'
	},
	emailMethod: {
		title: 'Delay Emails',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		noteText: 'Delay offer emails after order placed',
		mobxSetter: (val) =>
			brandStore.setEmailMethod(val === 'true' ? 'schedule' : ''),
		mobxGetter: () =>
			brandStore.emailMethod === 'schedule' ? 'true' : 'false',
		required: false,
		size: 'small'
	},
	emailScheduleAmount: {
		title: 'Delay Amount',
		type: 'number',
		conditional: (state) => state.emailMethod === 'true',
		mobxSetter: (val, state) =>
			brandStore.setEmailSchedule(state.emailScheduleType, val),
		mobxGetter: () => brandStore.getEmailScheduleAmount(),
		required: true,
		size: 'small'
	},
	emailScheduleType: {
		title: 'Delay Type',
		type: 'select',
		options: {
			hours: 'Hours',
			days: 'Days',
			weeks: 'Weeks'
		},
		conditional: (state) => state.emailMethod === 'true',
		mobxSetter: (val, state) =>
			brandStore.setEmailSchedule(val, state.emailScheduleAmount),
		mobxGetter: () => brandStore.getEmailScheduleType(),
		required: true
	},
	promoteOnly: {
		title: 'Promote Only',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		noteText: 'If true, will NOT fetch orders',
		mobxSetter: (val) => brandStore.setPromoteOnly(val),
		mobxGetter: () => (brandStore.promoteOnly ? 'true' : 'false'),
		required: false,
		size: 'small'
	},
	productType: {
		title: 'Product Type',
		type: 'text',
		noteText:
			'Optional: Only useful if set to same value for more than one brand; they will ignore eachother',
		invalidText: 'Please enter a valid product type',
		mobxSetter: (val) => brandStore.setProductType(val),
		mobxGetter: () => brandStore.productType,
		size: 'long',
		placeholder: 'ex. coffee'
	},
	primaryPartner: {
		title: 'Primary Partner',
		type: 'brand',
		limit: 1,
		noteText: 'Optional: Will always try to promote this brand',
		mobxSetter: (brandId) => brandStore.setPrimaryPartner(brandId),
		mobxGetter: () => brandStore.primaryPartner
	},
	payoutViaAffiliate: {
		title: 'Payout Via Affiliate Network',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		noteText: 'Do not invoice via Stripe',
		mobxSetter: (val) => brandStore.setPayoutViaAffiliate(val),
		mobxGetter: () => (brandStore.payoutViaAffiliate ? 'true' : 'false'),
		required: false,
		size: 'small'
	},
	brandTags: {
		title: 'Brand tags',
		type: 'text',
		invalidText: 'Please enter valid tags',
		mobxSetter: (val) =>
			brandStore.setTags(
				val.split(',').map((item) => item.trim().toLowerCase())
			),
		mobxGetter: () => brandStore.tags.join(', '),
		size: 'long',
		noteText: 'Enter comma-separated tags',
		placeholder: 'example, sample'
	},
	adminProxyShop: {
		title: 'Admin proxy shop',
		type: 'brand',
		max: 1,
		conditional: () => brandStore.adminShop === true,
		brandFilter: (brand) => brand.shopifyShop,
		mobxSetter: (brandId) => {
			if (!brandId) {
				brandStore.setAdminProxyShop('');
				return;
			}

			const selectedBrand = adminStore.allBrands.find(
				(brand) => brand.docId === brandId
			);

			brandStore.setAdminProxyShop(selectedBrand.shopifyShop);
		},
		mobxGetter: () => {
			if (!brandStore.adminProxyShop) {
				return 'unknown';
			}

			const selectedBrand = adminStore.allBrands.find(
				(brand) => brand.shopifyShop === brandStore.adminProxyShop
			);

			return selectedBrand ? selectedBrand.docId : 'unknown';
		}
	},
	offerManagedCode: {
		title: 'Code',
		type: 'text',
		invalidText: 'Please enter a valid code',
		mobxSetter: (val) => brandStore.setOfferManagedCode(val),
		mobxGetter: () => brandStore.offerManagedCode,
		conditional: (state) => state.offerCodeSource === 'managed',
		required: true,
		size: 'long'
	},
	allowRepeatEmails: {
		title: 'Allow Repeat Emails',
		type: 'select',
		options: {
			false: 'False',
			true: 'True'
		},
		noteText:
			'Allow sending emails to same e-mail address repeatedly (within timeframe)',
		mobxSetter: (val) => brandStore.setAllowRepeatEmails(val),
		mobxGetter: () => (brandStore.allowRepeatEmails ? 'true' : 'false')
	}
};

const integrations = {
	recharge: {
		rechargeToken: {
			title: 'Token',
			type: 'text',
			required: true,
			invalidText: 'Please enter valid token',
			size: 'long',
			mobxSetter: (val) =>
				brandStore.setIntegration(
					{ accessToken: val.trim(), parentIntegration: 'shopify' },
					'recharge'
				),
			mobxGetter: () => ''
		}
	},
	cratejoy: {
		cratejoySecret: {
			title: 'Secret',
			type: 'text',
			required: true,
			invalidText: 'Please enter valid secret',
			size: 'long',
			mobxSetter: (val, formState) =>
				brandStore.setIntegration({
					secret: val.trim(),
					user: formState.cratejoyUser.trim()
				}),
			mobxGetter: () => ''
		},
		cratejoyUser: {
			title: 'User',
			type: 'text',
			required: true,
			invalidText: 'Please enter valid user string',
			size: 'long',
			mobxSetter: () => {},
			mobxGetter: () => ''
		}
	},
	squarespace: {
		squareToken: {
			title: 'Access Token',
			type: 'text',
			required: true,
			invalidText: 'Please enter valid token',
			size: 'long',
			mobxSetter: (val) =>
				brandStore.setIntegration({ accessToken: val.trim() }),
			mobxGetter: () => ''
		}
	},
	woocommerce: {
		wooKey: {
			title: 'Key',
			type: 'text',
			required: true,
			invalidText: 'Please enter valid key',
			size: 'long',
			mobxSetter: (val, formState) =>
				brandStore.setIntegration({
					key: val.trim(),
					secret: formState.wooSecret.trim(),
					site: formState.wooSite.trim()
				}),
			mobxGetter: () => ''
		},
		wooSecret: {
			title: 'Secret',
			type: 'text',
			required: true,
			invalidText: 'Please enter valid secret',
			size: 'long',
			mobxSetter: () => {},
			mobxGetter: () => ''
		},
		wooSite: {
			title: 'Site',
			type: 'text',
			required: true,
			invalidText: 'Please enter valid site url',
			size: 'long',
			mobxSetter: () => {},
			mobxGetter: () => ''
		}
	},
	custom: {
		customType: {
			title: 'Type',
			type: 'select',
			options: {
				api: 'API',
				pixel: 'Pixel'
			},
			mobxSetter: (val) =>
				brandStore.setIntegration({
					type: val
				}),
			mobxGetter: () => '',

			required: true,
			size: 'long'
		}
	}
};

const collections = {
	offerDetails: [
		'cardImage',
		'offerTagline',
		'offerCodeSource',
		'offerGlobalCode',
		'offerManagedCode',
		'offerType',
		'offerAmount',
		'offerLink',
		'payoutViaAffiliate'
	],
	brandAddress: [
		'brandAddress',
		'brandCity',
		'brandState',
		'brandZip',
		'brandCountry'
	],
	brandDetails: ['brandLogo', 'brandName'],
	finalizeBrand: [
		'demoGender',
		'brandCategory',
		'cpaType',
		'cpa',
		'billingMethod',
		'supportEmail',
		'freeConversionsUntil',
		'brandTags'
	],
	advancedSettings: [
		'abortRenewals',
		'fetchRenewals',
		'dataCleanup',
		'emailMethod',
		'emailScheduleAmount',
		'emailScheduleType',
		'promoteOnly',
		'productType',
		'adminProxyShop',
		'allowRepeatEmails'
	]
};

export const resolveFields = (requested = []) =>
	requested.reduce((obj, name) => {
		obj[name] = fields[name];
		return obj;
	}, {});

export const resolveIntegration = (name) => integrations[name];

export const resolveCollection = (name) => resolveFields(collections[name]);
