import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import SelectSearch from 'react-select-search';

import '../css/SelectSearch.css';

@inject('adminStore', 'brandStore')
@observer
class BrandSelect extends React.Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		adminStore: PropTypes.object.isRequired,
		brandStore: PropTypes.object.isRequired,
		placeholder: PropTypes.func,
		includeCurrentBrand: PropTypes.bool,
		brandFilter: PropTypes.func
	};

	static defaultProps = {
		includeCurrentBrand: false,
		brandFilter: () => true
	};

	async componentDidMount() {
		const { adminStore } = this.props;

		if (
			!adminStore.loadingBrands &&
			!(adminStore.allBrands && adminStore.allBrands.length)
		) {
			await adminStore.refreshAllBrands();
			this.forceUpdate();
		}
	}

	resolveBrand(brandId) {
		const brand = this.props.adminStore.allBrands.find(
			(brand) => brand.docId === brandId
		);

		return brand || null;
	}

	filterCurrent(brand) {
		return this.props.includeCurrentBrand
			? true
			: brand.docId !== this.props.brandStore.brandId;
	}

	get brandsFiltered() {
		return this.props.adminStore.allBrands.filter(this.props.brandFilter);
	}

	get brandsActive() {
		return this.brandsFiltered
			.filter((brand) => brand.active)
			.filter((brand) => this.filterCurrent(brand));
	}

	get brandsIntegrated() {
		return this.brandsFiltered
			.filter((brand) => !brand.active && brand.integrated)
			.filter((brand) => this.filterCurrent(brand));
	}

	get brandsOther() {
		return this.brandsFiltered
			.filter((brand) => !(brand.active || brand.integrated))
			.filter((brand) => this.filterCurrent(brand));
	}

	render() {
		const { onChange, adminStore, placeholder } = this.props;
		const loading = !adminStore.allBrands.length;

		return (
			<SelectSearch
				onChange={(obj) =>
					onChange(
						this.resolveBrand(obj.value),
						this.select.componentNode.querySelector(
							'.select-search-box__search'
						)
					)
				}
				type="select"
				ref={(select) => (this.select = select)}
				name="previewSelect"
				placeholder={
					placeholder
						? placeholder(loading, adminStore.allBrands)
						: 'Select brand'
				}
				options={[
					{
						type: 'group',
						name: `Active (${this.brandsActive.length})`,
						items: this.brandsActive.map((brand) => ({
							name: brand.name,
							value: brand.docId
						}))
					},
					{
						type: 'group',
						name: `Integrated (${this.brandsIntegrated.length})`,
						items: this.brandsIntegrated
							.filter(
								(brand) => !brand.active && brand.integrated
							)
							.map((brand) => ({
								name: brand.name,
								value: brand.docId
							}))
					},
					{
						type: 'group',
						name: `Other (${this.brandsOther.length})`,
						items: this.brandsOther.map((brand) => ({
							name: brand.name,
							value: brand.docId
						}))
					}
				]}
			/>
		);
	}
}

export default BrandSelect;
