import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import _ from 'lodash';

import BrandSelect from '../../components/BrandSelect';
import SaveButton from '../../components/SaveButton';

import styles from '../../css/AdminManagePartners.module.css';

@inject('brandStore', 'adminStore')
@observer
class AdminManagePartners extends React.Component {
   static propTypes = {
      brandStore: PropTypes.object.isRequired,
      adminStore: PropTypes.object.isRequired
   };

   state = {
      active: 'toPromoteList',
      toPromoteList: [],
      promotedByList: [],
      loading: true
   };

   async componentDidMount() {
      await this.refreshBrands();
   }

   async refreshBrands() {
      await this.props.adminStore.refreshAllBrands([ 'docId', 'whitelist', 'name' ]);
      this.setState({
         toPromoteList: [ ...this.props.brandStore.whitelist ],
         promotedByList: this.initialPromotedBy(),
         loading: false
      });
   }

   initialPromotedBy() {
      const { adminStore, brandStore } = this.props;

      return toJS(adminStore.allBrands)
         .filter(brand => brand.whitelist && brand.whitelist.includes(brandStore.brandId))
         .map(brand => brand.docId);
   }

   addBrand(brand) {
      const list = this.state[this.state.active];

      if (list.includes(brand.docId)) {
         return;
      }

      this.setState({
         [this.state.active]: [ ...list, brand.docId ]
      });
   }

   removeBrand(brandId, listType) {
      const list = this.state[listType];

      if (!list.includes(brandId)) {
         return;
      }

      this.setState({
         [listType]: _.without(list, brandId)
      });
   }

   brandLookup(brandId) {
      return (
         this.props.adminStore.allBrands.find(brand => brand.docId === brandId) || { name: brandId }
      );
   }

   async handleFormSubmit() {
      const { toPromoteList, promotedByList } = this.state;
      const { adminStore, brandStore } = this.props;

      brandStore.setWhitelist(toPromoteList);

      const toRemove = this.initialPromotedBy().filter(
         brandId => !promotedByList.includes(brandId)
      );
      const toAdd = promotedByList.filter(brandId => !this.initialPromotedBy().includes(brandId));

      await Promise.all([
         ...toRemove.map(brandId =>
            adminStore.updateBrandWhitelist(brandId, brandStore.brandId, 'remove')
         ),
         ...toAdd.map(brandId =>
            adminStore.updateBrandWhitelist(brandId, brandStore.brandId, 'add')
         )
      ]);

      await this.refreshBrands();
   }

   formDataChanged() {
      return !(
         _.isEqual(this.state.toPromoteList.sort(), toJS(this.props.brandStore.whitelist).sort()) &&
         _.isEqual(this.state.promotedByList, this.initialPromotedBy())
      );
   }

   renderSelectorSection(type) {
      const { active } = this.state;
      const list = this.state[type].sort(
         (a, b) =>
            this.props.adminStore.allBrands.findIndex(brand => brand.docId === a) -
            this.props.adminStore.allBrands.findIndex(brand => brand.docId === b)
      );

      return (
         <div key={type}>
            <h6
               className={`${styles.selectorHeader} ${active === type ? styles.active : ''}`}
               onClick={() => {
                  this.setState({
                     active: type
                  });
               }}
            >
               {type === 'toPromoteList' ? 'To Promote' : 'Be Promoted By'}
            </h6>

            {!!list.length && (
               <ul className={styles.brandList}>
                  {list.map(brandId => (
                     <li key={brandId}>
                        {this.brandLookup(brandId).name}
                        <button
                           onClick={() => this.removeBrand(brandId, type)}
                           className={styles.delBtn}
                        >
                           x
                        </button>
                     </li>
                  ))}
               </ul>
            )}
            {!list.length && <p>No brands</p>}
         </div>
      );
   }

   render() {
      const { loading } = this.state;
      const { brandStore } = this.props;

      if (loading) {
         return <p>Loading brands...</p>;
      }

      return (
         <div className={styles.container}>
            <BrandSelect
               onChange={(brand, el) => {
                  this.addBrand(brand);
                  el.focus();
               }}
            />

            <h5 className={styles.brandHeader}>For {brandStore.name}...</h5>

            <div className={styles.selectedContainer}>
               {[ 'toPromoteList', 'promotedByList' ].map(type => this.renderSelectorSection(type))}
            </div>

            <SaveButton
               className={styles.saveBtn}
               onSubmitHandler={e => this.handleFormSubmit(e)}
               enableCondition={this.formDataChanged()}
            />
         </div>
      );
   }
}

export default AdminManagePartners;
