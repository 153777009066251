import { flow, observable } from 'mobx';
import { db } from '../firebase';
import { captureException } from '../util';
import _ from 'lodash';

import dbStore from './dbStore';

export class AdminStore {
	allBrands = [];

	@observable
	loadingBrands = false;

	refreshAllBrands = flow(function* (props = 'all') {
		try {
			this.loadingBrands = true;
			this.allBrands = yield db.getAllBrands(props);
			this.loadingBrands = false;
		} catch (e) {
			e.message = `refreshBrands(): ${e.message}`;
			captureException(e);
		}
	});

	setBrandSortFactor = flow(function* (brandId, value) {
		try {
			dbStore.setSavingBool(true);
			yield db.doUpdateBrand(brandId, { offerSortFactor: value });
			dbStore.setSavingBool(false);
		} catch (e) {
			e.message = `setBrandSortFactor(): ${e.message}`;
			captureException(e);
		}
	});

	setBrandRatioThreshold = flow(function* (brandId, value) {
		try {
			dbStore.setSavingBool(true);
			yield db.doUpdateBrand(brandId, { ratioThreshold: value });
			dbStore.setSavingBool(false);
		} catch (e) {
			e.message = `setBrandRatioThreshold(): ${e.message}`;
			captureException(e);
		}
	});

	setBrandThirdSlotFactor = flow(function* (brandId, value) {
		try {
			dbStore.setSavingBool(true);
			yield db.doUpdateBrand(brandId, { thirdSlotFactor: value });
			dbStore.setSavingBool(false);
		} catch (e) {
			e.message = `setBrandThirdSlotFactor(): ${e.message}`;
			captureException(e);
		}
	});

	updateBrandWhitelist = flow(function* (brandId, currentBrand, op) {
		try {
			dbStore.setSavingBool(true);

			const initialList =
				this.allBrands.find((brand) => brand.docId === brandId)
					.whitelist || [];

			yield db.updateBrandWhitelist(
				brandId,
				op === 'add'
					? [...initialList, currentBrand]
					: _.without(initialList, currentBrand)
			);

			dbStore.setSavingBool(false);
		} catch (e) {
			e.message = `setBrandThirdSlotFactor(): ${e.message}`;
			captureException(e);
		}
	});
}

export default new AdminStore();
