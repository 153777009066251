import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import Logo from './Logo';

import styles from '../css/Login.module.css';

const withLoginLayout = headerText => Component => {
   @inject('authStore')
   @observer
   class WithLoginLayout extends React.Component {
      state = {
         subheading: ''
      };
      static propTypes = {
         authStore: PropTypes.object.isRequired
      };

      componentDidMount() {
         this.props.authStore.clearErrors();
      }

      setSubheading(text) {
         this.setState({
            subheading: text
         });
      }

      render() {
         const { authStore } = this.props;
         const { subheading } = this.state;

         return (
            <div className={styles.container}>
               <div className={styles.content}>
                  <h2 className={`mb-0 ${styles.heading}`}>
                     <Logo />
                  </h2>
                  <h4 className={styles.componentHeading}>
                     <span className={styles.headerMainText}>
                        {headerText}
                        <small className={styles.subheading}>{subheading}</small>
                     </span>
                  </h4>

                  {authStore.state === 'error' && (
                     <p
                        data-testid="loginErrorMessage"
                        className="alert text-center alert-danger"
                        role="alert"
                     >
                        {authStore.errorMessage}
                     </p>
                  )}

                  <Component setSubheading={text => this.setSubheading(text)} />
               </div>
            </div>
         );
      }
   }

   return WithLoginLayout;
};

export default withLoginLayout;
