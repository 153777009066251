import React from 'react';
import PropTypes from 'prop-types';

import { observer, inject } from 'mobx-react';

const platformMessaging = {
   woocommerce: 'WooCommerce',
   bigcommerce: 'BigCommerce',
   cratejoy: 'Cratejoy',
   squarespace: 'Squarespace',
   magento: 'Magento',
   custom: 'custom platforms',
   other: 'your platform',
   lemonstand: 'Lemonstand'
};

@inject('brandStore', 'wizardStore')
@observer
class IntegrationFallback extends React.Component {
   state = {};

   static propTypes = {
      brandStore: PropTypes.object.isRequired,
      wizardStore: PropTypes.object.isRequired
   };

   componentDidMount() {
      const { wizardStore } = this.props;
      // Listen for next clicks via wizard
      wizardStore.nextClickEmitter.addEventListener('click', () => wizardStore.advance());
   }

   render() {
      const { brandStore } = this.props;

      const platform = platformMessaging[brandStore.platformSelection];

      return (
         <div>
            <p>
               Almost there! You indicated earlier that you're using <strong>{platform}</strong> as
               your eCommerce platform. Currently our integration with {platform} is more of a
               manual process that we'll be happy to walk you through.
            </p>

            <p>
               Simply click <em>Finish</em> below, and continue to start your review. We'll follow
               up with you shortly.
            </p>
         </div>
      );
   }
}
export default IntegrationFallback;
