import { auth, EmailAuthProvider } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = async (email, password) => {
	const response = await auth.createUserWithEmailAndPassword(email, password);
	return response.user;
};

// Send email verification
export const doSendEmailVerification = (user) => user.sendEmailVerification();

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
	auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
	auth.currentUser.updatePassword(password);

// Reauthenticate
export const doReauthenticate = (email, password) =>
	auth.currentUser.reauthenticateWithCredential(
		EmailAuthProvider.credential(email, password)
	);

// Get user token
export const getUserToken = () => auth.currentUser.getIdToken();
