import React from 'react';
import { captureException } from '../util';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from '../css/ErrorBoundry.module.css';

const withErrorBoundry = Component => {
   @inject('dbStore')
   @observer
   class ErrorBoundry extends React.Component {
      static propTypes = {
         dbStore: PropTypes.object.isRequired
      };

      state = {
         hasError: false
      };

      componentDidCatch(error, info) {
         this.setState({ hasError: true });

         captureException(error, info);
      }

      render() {
         const { dbStore } = this.props;
         const { hasError } = this.state;

         const error = dbStore.displayErrorState || hasError;

         if (error) {
            return (
               <div className={styles.container}>
                  <p>Something went wrong! :(</p>
                  <p>Please refresh your browser and try again.</p>
               </div>
            );
         }

         return <Component {...this.props} />;
      }
   }

   return ErrorBoundry;
};

export default withErrorBoundry;
