import { observable, action, reaction, toJS } from 'mobx';
import { compose } from 'recompose';

import withWizardToBuilder from '../components/withWizardToBuilder';

class WizardStore {
	@observable visible = false;
	@observable step = null;
	@observable StepComponent = null;
	@observable currentStep = null;
	@observable submitTriggered = false;
	@observable saving = false;
	@observable savingPromise = null;
	@observable finalPage = false;
	@observable nextButtonText = 'Next';
	@observable finalNextButtonText = 'Finish';

	savingResolve = null;
	nextClickEmitter = new EventTarget();
	onFinish = () => {};

	@observable steps = [];

	@action
	addStep(stepToAdd) {
		if (
			stepToAdd.id &&
			this.steps.find((step) => stepToAdd.id === step.id)
		) {
			return;
		}

		this.steps.push(stepToAdd);
	}

	@action
	removeStep(id) {
		if (!this.steps.find((step) => id === step.id)) {
			return;
		}

		this.steps = this.steps.filter((step) => step.id !== id);
	}

	@action
	setSaving(bool) {
		this.saving = bool;
	}

	@action
	setSavingPromise(promise) {
		this.savingPromise = promise;
	}

	@action
	startWizard(steps, opts = {}) {
		this.steps = steps.map((step) => ({ type: 'standard', ...step }));
		this.step = 0;
		this.visible = true;
		this.onFinish =
			typeof opts.onFinish === 'function'
				? () => opts.onFinish()
				: () => {};
	}

	@action
	toggle() {
		this.visible = !this.visible;
	}

	@action
	hide() {
		this.visible = false;
	}

	@action
	show() {
		this.visible = true;
	}

	@action
	updateFinalPage() {
		this.finalPage = this.step === this.steps.length - 1;
	}

	@action
	advance() {
		// Finish wizard if this was the final page
		if (this.finalPage) {
			this.visible = false;
			this.onFinish();
			return;
		}

		this.step = this.step + 1;
	}

	@action
	handlePreviousClick() {
		this.step = this.step >= 1 ? this.step - 1 : 0;
	}

	@action
	currentStepUpdate() {
		// Reset to remove listeners
		this.nextClickEmitter = new EventTarget();

		if (this.step === null) {
			this.StepComponent = null;
			return;
		}

		this.currentStep = this.steps[this.step];
		this.StepComponent =
			this.currentStep.type === 'builder'
				? compose(withWizardToBuilder)(toJS(this.currentStep))
				: this.currentStep.component;
	}

	currentStepReaction = reaction(
		() => this.step,
		() => {
			this.currentStepUpdate();
		}
	);

	visibleReaction = reaction(
		() => this.visible,
		() => {
			this.currentStepUpdate();
		}
	);

	finalPageUpdate = reaction(
		() => this.step,
		() => {
			this.updateFinalPage();
		}
	);

	finalPageUpdateFromLen = reaction(
		() => this.steps.length,
		() => {
			this.updateFinalPage();
		}
	);

	setSaving = reaction(
		() => this.saving,
		(saving) => {
			if (!saving && typeof this.savingResolve === 'function') {
				this.savingResolve();
			}

			if (saving) {
				this.savingPromise = new Promise(
					(resolve) => (this.savingResolve = resolve)
				);
			}
		}
	);
}

export default new WizardStore();
